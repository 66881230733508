import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const logisticsManagementRoutes: RouteConfig = {
  path: '/logistics-management',
  name: 'LogisticsManagement',
  component: Layout,
  redirect: 'logistics',
  children: [
    {
      path: '/delivery-notice',
      name: 'DeliveryNotice',
      component: async () =>
        import(
          /* webpackChunkName: "delivery-notice" */ '@/views/logistics-management/delivery-notice/delivery-notice.vue'
        ),

      meta: {
        title: 'route.deliveryNotice',
        id: 1305
      }
    },
    {
      path: '/delivery-notice-details',
      name: 'DeliveryNoticeDetails',
      component: async () =>
        import(
          /* webpackChunkName: "delivery-notice-details" */ '@/views/logistics-management/delivery-notice/delivery-notice-details/delivery-notice-details.vue'
        ),
      meta: {
        title: 'route.deliveryNoticeDetails',
        id: 1440,
        hidden: true,
        parentId: 1305
      }
    },
    {
      path: '/logistics',
      name: 'Logistics',
      component: async () =>
        import(/* webpackChunkName: "logistics" */ '@/views/logistics-management/logistics/logistics.vue'),

      meta: {
        title: 'route.logistics',
        id: 1422
      }
    },
    {
      path: '/logistics-details',
      name: 'LogisticsDetails',
      component: async () =>
        import(
          /* webpackChunkName: "logistics-details" */ '@/views/logistics-management/logistics/logistics-details/logistics-details.vue'
        ),
      meta: {
        title: 'route.logisticsDetails',
        id: 1442,
        hidden: true,
        parentId: 1422
      }
    }
  ],
  meta: {
    title: 'route.logisticsManagement',
    id: 1304,
    icon: '#logistics'
  }
};
