import { ResourceUrlEnum } from '@/resource/enum';
import { CustomerResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';

class CustomerService {
  
  /**
   * 获取所有启用的客户
   */
  public getAllUsingCustomer(): Promise<Array<CustomerResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<CustomerResource>>(`${ResourceUrlEnum.productionOrder}/getCustomerList`)
        .then((res: ApiResponse<Array<CustomerResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const customerService = new CustomerService();
export default customerService;
