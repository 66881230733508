import { isExternal } from '@/utils/validate';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'svg-icon'
})
export default class SvgIcon extends Vue {
  @Prop({ type: String, default: '' }) public name!: string;
  @Prop({ type: String, default: '' }) public className!: string;
  @Prop({ type: String, default: '' }) public width!: string;
  @Prop({ type: String, default: '' }) public height!: string;
  @Prop({ type: String, default: '' }) public color!: string;

  public get isExternal(): boolean {
    return isExternal(this.name);
  }

  public get iconName(): string {
    return this.name;
  }

  public get svgClass(): string {
    if (this.className) {
      return 'svg-icon ' + this.className;
    } else {
      return 'svg-icon';
    }
  }

  public get styleExternalIcon(): object {
    return {
      mask: `url(${this.name}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${this.name}) no-repeat 50% 50%`
    };
  }

  public get svgStyle(): object {
    return {
      width: this.width,
      height: this.height,
      fill: this.color
    };
  }
}
