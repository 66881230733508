/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const receiptManagementRoutes: RouteConfig = {
  path: '/receipt-management',
  name: 'ReceiptManagement',
  component: Layout,
  redirect: 'all-receipt-list',
  children: [
    {
      path: '/all-receipt-list',
      name: 'AllReceiptList',
      component: async () =>
        import(
          /* webpackChunkName: "all-receipt-list" */ '@/views/receipt-management/all-receipt-list/all-receipt-list.vue'
        ),
      meta: {
        title: 'route.allReceiptList',
        id: 1023
      }
    },
    {
      path: '/prepress-receipt-list',
      name: 'PrepressReceiptList',
      component: async () =>
        import(
          /* webpackChunkName: "prepress-receipt-list" */ '@/views/receipt-management/prepress-receipt-list/prepress-receipt-list.vue'
        ),
      meta: {
        title: 'route.prepressReceiptList',
        id: 1538
      }
    },
    {
      path: '/printing-receipt-list',
      name: 'PrintingReceiptList',
      component: async () =>
        import(
          /* webpackChunkName: "printing-receipt-list" */ '@/views/receipt-management/printing-receipt-list/printing-receipt-list.vue'
        ),
      meta: {
        title: 'route.printingReceiptList',
        id: 1024
      }
    },
    {
      path: '/backend-receipt-list',
      name: 'BackendReceiptList',
      component: async () =>
        import(
          /* webpackChunkName: "backend-receipt-list" */ '@/views/receipt-management/backend-receipt-list/backend-receipt-list.vue'
        ),
      meta: {
        title: 'route.backendReceiptList',
        id: 1025
      }
    },
    {
      path: '/print-preview',
      name: 'PrintPreview',
      component: async () =>
        /* webpackChunkName: "print-preview" */ import(
          '@/views/receipt-management/all-receipt-list/print-preview/print-preview.vue'
        ),
      meta: {
        title: 'route.printPreview',
        id: 1862,
        hidden: true,
        parentId: 1023
      }
    }
  ],
  meta: {
    title: 'route.receiptManagement',
    icon: '#project',
    id: 1022
  }
};
