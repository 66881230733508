import { Component, Prop, Vue } from 'vue-property-decorator';
import { isExternal } from '@/utils/validate';

@Component({
  name: 'side-bar-item-link'
})
export default class SideBarItemLink extends Vue {
  @Prop({ required: true }) private to!: string;

  private isExternal = isExternal;
}
