/**
 * 生产订单变更单状态枚举
 */
export enum OrderChangeStatusEnum {
  /**
   * 待确认
   */
  pendingConfirm = 2,

  /**
   * 驳回
   */
  reject = 3,
  /**
   * 已生效
   */
  finished = 5
}
