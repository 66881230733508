import { Component, Emit, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';
import { DatePickerOptions, DatePickerType, ElDatePicker, Shortcut } from 'element-ui/types/date-picker';
import { ElRadioGroup } from 'element-ui/types/radio-group';
import moment from 'moment';
import i18n from '@/lang';
export interface OsShortcutsOptions {
  text: string;
  /**
   * 时间范围
   */
  rangeTime: () => [Date, Date];

  /** Whether radio is disabled */
  disabled?: boolean;

  /** Native 'name' attribute */
  name?: string;

  value: any;
}

@Component({
  components: {}
})
export default class OsTimePickerLinkage extends Vue {
  /**
   * 默认时间快捷选项值
   */
  @Prop({ required: false, default: undefined })
  public readonly defaultShortcuts!: any;

  @Prop({ type: String, required: false, default: 'small' })
  public readonly size!: 'medium' | 'small' | 'mini';

  @Prop({ type: String, required: false, default: 'daterange' })
  public readonly type!: DatePickerType;

  /**
   * 选中的时间范围
   */

  @ModelSync('rangeTime', 'change', { type: Array })
  public _selectedRangeTime!: Array<Date | string>;
  public get selectedRangeTime(): Array<Date | string> {
    return this._selectedRangeTime;
  }
  public set selectedRangeTime(value: Array<Date | string>) {
    const [startTime, endTime] = value;
    // 当选择日期且需要时分秒，默认把开始时间设为00:00:00，结束时间设为23:59:59
    if (this.type === 'daterange' && this.format === 'YYYY/MM/DD HH:mm:ss') {
      this._selectedRangeTime = [
        moment(startTime)
          .startOf('days')
          .format(this.format),
        moment(endTime)
          .endOf('days')
          .format(this.format)
      ];
      return;
    }

    this._selectedRangeTime = [moment(startTime).format(this.format), moment(endTime).format(this.format)];
  }

  /**
   * 时间快捷选项配置
   */
  @Prop({ type: Array, required: true })
  public readonly shortcutsOptions!: Array<OsShortcutsOptions>;

  @Prop({
    type: Object,
    required: false,
    default: () => {
      return { textColor: '#ffffff', fill: '#409EFF' };
    }
  })
  public readonly shotcutsStyle!: Pick<ElRadioGroup, 'textColor' | 'fill'>;

  @Prop({ type: String, required: false, default: 'YYYY/MM/DD HH:mm:ss' })
  public readonly format!: string;

  public pickerOptions: DatePickerOptions = {
    shortcuts: this.getElPickerShotcuts()
  };

  /**
   * 当前时间快捷选项值
   */
  public timeShortcuts = this.defaultShortcuts;

  public created(): void {
    // 根据默认的快捷选项，设置时间选择器
    if (this.timeShortcuts) {
      const shortcuts = this.shortcutsOptions.find(x => x.value === this.timeShortcuts);
      this.selectedRangeTime = shortcuts?.rangeTime() || [];
    }
  }

  public handleShortcutsChange(radio: any): void {
    const shortcuts = this.shortcutsOptions.find(x => x.value === radio);
    if (shortcuts && typeof shortcuts.rangeTime === 'function') {
      this.selectedRangeTime = shortcuts.rangeTime();
    }
    this.shortcutsChange(radio);
  }

  public handlePickerChange(): void {
    this.timeShortcuts = '';
  }

  @Emit()
  public shortcutsChange(radio: string): string {
    return radio;
  }

  @Watch('$i18n.locale')
  private watchLanguage(): void {
    this.pickerOptions.shortcuts = this.getElPickerShotcuts();
  }

  private getElPickerShotcuts(): Array<Shortcut> {
    return this.shortcutsOptions.map(x => {
      return {
        text: i18n.t(x.text) as string,
        onClick: (picker: ElDatePicker): void => {
          picker.$emit('pick', x.rangeTime());
        }
      };
    });
  }
}
