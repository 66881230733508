import Cookies from 'js-cookie';

// App
const sidebarStatusKey = 'sidebar_status';
export const getSidebarStatus = (): string | undefined => Cookies.get(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string): string | undefined =>
  Cookies.set(sidebarStatusKey, sidebarStatus);
// Language
const languageKey = 'language';
export const getLanguage = (): string | undefined => Cookies.get(languageKey);
export const setLanguage = (language: string): string | undefined => Cookies.set(languageKey, language);
