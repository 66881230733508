import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import SideBarItem from './side-bar-item/side-bar-item.vue';
import variables, { IScssVariables } from '@/styles/_variables.scss';
import { PermissionModule } from '@/store/modules/permission';
import { RouteConfig } from 'vue-router';

@Component({
  name: 'side-bar',
  components: {
    SideBarItem
  }
})
export default class SideBar extends Vue {
  public get sidebar(): { opened: boolean; withoutAnimation: boolean } {
    return AppModule.sidebar;
  }

  public get routes(): Array<RouteConfig> {
    return PermissionModule.routes;
  }

  public get variables(): IScssVariables {
    return variables;
  }

  public get activeMenu(): string {
    const route = this.$route;
    const { meta, path } = route;
    if (meta!.activeMenu) {
      return meta!.activeMenu;
    }
    return path;
  }

  public get isCollapse(): boolean {
    return !this.sidebar.opened;
  }
}
