import { ResourceUrlEnum } from '@/resource/enum';
import { LoginRes, CaptchaRes, CaptchaCheckRes, PermissionRoute, UserResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
class AuthService {
  public login(username: string, password: string, domainName: string): Promise<LoginRes> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<LoginRes>(`${ResourceUrlEnum.auth}/login`, { username, password, domainName })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<null>(`${ResourceUrlEnum.auth}/logOut`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getPermissionMenu(): Promise<Array<PermissionRoute>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<PermissionRoute>>(`${ResourceUrlEnum.userCenter}/getMenu`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getPermissionButton(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<string>>(`${ResourceUrlEnum.userCenter}/getButton`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getCaptcha(captchaType: string, clientUid: string, ts: number): Promise<CaptchaRes> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CaptchaRes>(`${ResourceUrlEnum.auth}/getCaptcha`, { captchaType, clientUid, ts })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public checkCaptcha(params: {
    captchaType: string;
    pointJson: string;
    token: string;
    username: string;
    domainName: string;
  }): Promise<CaptchaCheckRes> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CaptchaCheckRes>(`${ResourceUrlEnum.auth}/checkCaptcha`, params)
        .then(res => {
          if (res.code !== 0) {
            reject();
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public sendVerificationCode(email: string, domainName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<null>(`${ResourceUrlEnum.userCenter}/sendVerificationCode`, { email, domainName })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public forgetPassword(resetParams: {
    domainName: string;
    email: string;
    newPassword: string;
    verificationCode: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<null>(`${ResourceUrlEnum.userCenter}/forgetPassword`, resetParams)
        .then(res => {
          if (res.code !== 0) {
            reject(res.message);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getUserInfo(): Promise<UserResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<UserResource>(`${ResourceUrlEnum.userCenter}/getInfo`)
        .then((res: ApiResponse<UserResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public editPassword(password: string, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.userCenter}/editPassword`, { password }, { headers: { token } })
        .then((res: ApiResponse<void>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public editUserInfo(info: { avatar: string; email: string; mobile: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(ResourceUrlEnum.userCenter, info)
        .then((res: ApiResponse<void>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const authService = new AuthService();
export default authService;
