import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { resetRouter } from '@/router';
import localStorage from '@/utils/local-storage';
import { PermissionModule } from './permission';
import { authService } from '@/api';
import { LoginRes, UserResource } from '@/resource/model';
import { messageError } from '@/utils';
import { webSocket } from '@/utils/websocket';
import { TagsViewModule } from './tags-view';

export interface IUserState {
  token: string;
  name: string;
  avatar: string;
  openMiniLoginPage: boolean;
  isFirstLogin: boolean;
  account: string;
  /**
   * 是否需要重新加载用户以及权限信息
   */
  reLoading: boolean;
  resetPassDialog: boolean;
  email: string;
  mobile: string;
  domainName: string;
}

@Module({ dynamic: true, store, name: 'user' })
class UserState extends VuexModule implements IUserState {
  public token = localStorage.get<string>('token') || '';
  public name = '';
  public avatar = '';
  public openMiniLoginPage = false;
  public isFirstLogin = true;
  public account = '';
  public reLoading = true;
  public resetPassDialog = false;
  public email = '';
  public mobile = '';
  public domainName = '';
  private shortTimeToken = '';

  @Action({ rawError: true })
  public async Login(form: { username: string; password: string; domain: string }): Promise<void> {
    try {
      const loginRes: LoginRes = await authService.login(form.username, form.password, form.domain);
      this.setOpenMiniLoginPage(false);
      if (loginRes.firstLoginFlag === 1) {
        this.setShortTimeToken(loginRes.userToken);
        this.setResetPassDialog(true);
        return Promise.reject();
      }
      if (loginRes.userToken) {
        this.setToken(loginRes.userToken);
      }
      return Promise.resolve();
    } catch (error) {
      messageError(error);
      return Promise.reject();
    }
  }

  @Action({ rawError: true })
  public async Logout(): Promise<void> {
    try {
      this.setOpenMiniLoginPage(false);
      await authService.logout();
      this.clearToken();
      TagsViewModule.DelAllViews();
      this.setReLoading(true);
      return Promise.resolve();
    } catch (error) {
      messageError(error);
      return Promise.reject();
    }
  }

  @Action({ rawError: true })
  public async EditPassword(password: string): Promise<void> {
    try {
      await authService.editPassword(password, this.shortTimeToken);
      this.setToken(this.shortTimeToken);
      this.setShortTimeToken('');
      return Promise.resolve();
    } catch (error) {
      messageError(error);
      return Promise.reject();
    }
  }

  @Action({ rawError: true })
  public async EditUserInfo(info: { avatar: string; email: string; mobile: string }): Promise<void> {
    try {
      await authService.editUserInfo(info);
      this.editUserBaseInfo(info);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 重置token，此操作会调起mini登录的dialog
   */
  @Action
  public ResetToken(): void {
    this.clearToken();
    if (!this.openMiniLoginPage) {
      this.setOpenMiniLoginPage(true);
    }
  }

  @Mutation
  public clearToken(): void {
    localStorage.remove('token');
    this.token = '';
    // TODO 优化此处代码
    webSocket.disConnect('messageCenter');
  }

  /**
   * 修改用户信息
   * @param info
   */
  @Mutation
  public editUserBaseInfo(info: { avatar: string; email: string; mobile: string }): void {
    this.avatar = info.avatar;
    this.email = info.email;
    this.mobile = info.mobile;
  }

  /**
   * 设置用户信息
   * @param userInfo
   */
  @Mutation
  public setUserInfo(userInfo: UserResource): void {
    this.account = userInfo.userName;
    this.name = userInfo.realName;

    this.avatar = userInfo.avatar;
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;
  }

  /**
   * 设置账号信息
   * @param
   */
  @Mutation
  public setAccountInfo(info: { account: string; domainName: string }): void {
    this.account = info.account;
    this.domainName = info.domainName;
  }

  /**
   * 设置是否需要重新加载信息
   */
  @Mutation
  public setReLoading(status: boolean): void {
    this.reLoading = status;
    if (status) {
      PermissionModule.ClearPermission();
      resetRouter();
    }
  }

  @Mutation
  public setResetPassDialog(isOpen: boolean): void {
    this.resetPassDialog = isOpen;
  }

  @Mutation
  private setToken(token: string): void {
    if (token) {
      localStorage.set('token', token);
      this.token = token;
    }
  }

  @Mutation
  private setOpenMiniLoginPage(isOpen: boolean): void {
    this.openMiniLoginPage = isOpen;
  }

  @Mutation
  private setShortTimeToken(token: string): void {
    this.shortTimeToken = token;
  }
}

export const UserModule = getModule(UserState);
