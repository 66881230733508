import { ProductionOrderStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { BaseService, Paging, Query } from '../base';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { translation } from '@/utils';

class ProductionOrderService extends BaseService<ProductionOrderResource> {
  /**
   * 接受订单
   * @param id 订单ID
   * @returns
   */
  public accept(id: number): Promise<void> {
    if (!id) {
      throw new Error('Arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}/orderTaking`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 确认排产
   * @param id 订单ID
   * @returns
   */
  public confirmProduction(id: number): Promise<void> {
    if (!id) {
      throw new Error('Arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}/scheduling`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 拒绝订单
   * @param id 订单ID
   * @returns
   */
  public reject(id: number, refuseReason: string): Promise<void> {
    if (!id && !refuseReason) {
      throw new Error('Arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}/refuse`, {}, { params: { id, refuseReason } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 取消接单
   * @param id 订单ID
   * @returns
   */
   public cancel(id: number): Promise<void> {
    if (!id) {
      throw new Error('Arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}/cancel`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 提交验收
   * @param id 订单ID
   */
  public submitComplete(id: number): Promise<void> {
    if (!id) {
      throw new Error('Arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}/finish`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID获取详情
   * @param id ID
   * @returns 数据对象
   */
  public getById(id: number): Promise<ProductionOrderResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionOrderResource>(`${ResourceUrlEnum.productionOrder}`, { params: { id } })
        .then((res: ApiResponse<ProductionOrderResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Query<ProductionOrderResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderResource>>(`${ResourceUrlEnum.productionOrder}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProductionOrderResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存订单
   * @param resource 订单对象
   */
  public post(resource: ProductionOrderResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }
    throw new Error('No impl');
  }

  /**
   * 编辑订单
   * @param resource 订单对象
   */
  public put(resource: ProductionOrderResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    throw new Error('No impl');
  }

  /**
   * 根据ID删除订单
   * @param id 订单ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }

    throw new Error('No impl');
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    return 'order.' + ProductionOrderStatusEnum[status];
  }

  /**
   * 根据状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: ProductionOrderStatusEnum): string {
    let className = '';
    switch (Number(status)) {
      case ProductionOrderStatusEnum.waitingAccept:
        className = 'danger-dot';
        break;
      case ProductionOrderStatusEnum.waitingPlan:
        className = 'warning-dot';
        break;
      case ProductionOrderStatusEnum.inProgress:
        className = 'primary-dot';
        break;
      case ProductionOrderStatusEnum.waitingConfirm:
        className = 'warning-dot';
        break;
      case ProductionOrderStatusEnum.completed:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 根据状态返回状态样式类型
   * @param status 状态
   * @returns 状态样式类型
   */
  public getStatusType(status: ProductionOrderStatusEnum): string {
    let typeName = '';
    switch (Number(status)) {
      case ProductionOrderStatusEnum.waitingAccept:
        typeName = 'warning';
        break;
      case ProductionOrderStatusEnum.waitingPlan:
        typeName = 'danger';
        break;
      case ProductionOrderStatusEnum.inProgress:
        typeName = 'primary';
        break;
      case ProductionOrderStatusEnum.waitingConfirm:
        typeName = 'warning';
        break;
      case ProductionOrderStatusEnum.completed:
        typeName = 'success';
        break;
      default:
        typeName = '';
    }

    return typeName;
  }

  /**
   * 获取订单可选状态集合
   * @returns
   */
  public getStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in ProductionOrderStatusEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getStatusI18Key(Number(key))),
          value: key
        });
      }
    }

    return list;
  }
}
const productionOrderService = new ProductionOrderService();
export default productionOrderService;
