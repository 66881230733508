export enum DeliveryModeEnum {
  /**
   * 第三方物流
   */
  TPL = 1,
  /**
   * 专车
   */
  specialTrain,
  /**
   * 自提
   */
  selfPickUp
}
