/**
 * 第一位区分系统 1：平台端系统、2：供应商系统
 * 第二三位区分模块 01：项目管理、02：印前任务、03：客户管理、04：产品管理、05：基础数据、06：工艺管理、
 *               07：供应商管理、08：生产管理、09：物流管理、10：安装管理、11：财务管理
 */
export enum PageQueryPlanEnum {
  /**
   * 单据管理 01
   *  --全部单据 01
   *   --功能：列表页 01
   */
  allReceiptList = 2010101,
  /**
   *  单据管理 01
   *  --印前单据 02
   *   --功能：列表页 01
   */
  prepressReceiptList = 2010201,

  /**
   *  单据管理 01
   *  --输出单据 03
   *   --功能：列表页 01
   */
  printingReceiptList = 2010301,
  /**
   *  单据管理 01
   *  --后道单据 04
   *   --功能：列表页 01
   */
  backendReceiptList = 2010401,

  /**
   * 物流管理 02
   *  --发货通知单 01
   *    --功能：列表 01
   */
  deliveryNotice = 2020101,
  /**
   * 物流管理 02
   *  --物流单 02
   *   --功能：列表 01
   */
  logistics = 2020201,
  /**
   * 生产管理 08
   *  --生产订单 01
   *   --功能：列表 01
   */
  productionOrder = 2080101,
  /**
   * 生产管理 08
   *  --生产变更订单 02
   *   --功能：列表 01
   */
  productionOrderChange = 2080201
}
