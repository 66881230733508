/**
 * 订单产品状态
 */
export enum ProductionProductStatusEnum {
  /**
   * 待排产
   */
  waitPlan = 0,
  /**
   * 待制作
   */
  new = 1,
  /**
   * 制作中
   */
  processing = 2,
  /**
   * 已完成
   */
  completed = 3,
  /**
   * 已取消
   */
  canceled = -1
}
