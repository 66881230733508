/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const productionManagementRoutes: RouteConfig = {
  path: '/production-management',
  name: 'ProductionManagement',
  component: Layout,
  redirect: 'production-order',
  children: [
    {
      path: '/production-order',
      name: 'ProductionOrder',
      component: async () =>
        import(
          /* webpackChunkName: "production-order" */ '@/views/production-management/production-order/production-order.vue'
        ),
      meta: {
        title: 'route.productionOrder',
        id: 992
      }
    },
    {
      path: '/production-order-detail',
      name: 'ProductionOrderDetail',
      component: async () =>
        import(
          /* webpackChunkName: "production-order-detail" */ '@/views/production-management/production-order/production-order-detail/production-order-detail.vue'
        ),
      meta: {
        title: 'route.productionOrderDetail',
        id: 993,
        hidden: true,
        parentId: 992
      }
    },
    {
      path: '/production-order-change',
      name: 'ProductionOrderChange',
      component: async () =>
        import(
          /* webpackChunkName: "production-order-change" */ '@/views/production-management/production-order-change/production-order-change.vue'
        ),
      meta: {
        title: 'route.productionOrderChange',
        id: 994
      }
    },
    {
      path: '/production-order-change-detail',
      name: 'ProductionOrderChangeDetail',
      component: async () =>
        import(
          /* webpackChunkName: "production-order-change-detail" */ '@/views/production-management/production-order-change/production-order-change-detail/production-order-change-detail.vue'
        ),
      meta: {
        title: 'route.productionOrderChangeDetail',
        id: 995,
        hidden: true,
        parentId: 994
      }
    },
    {
      path: '/shipping-notice',
      name: 'ShippingNotice',
      component: async () =>
        import(
          /* webpackChunkName: "shipping-notice" */ '@/views/production-management/shipping-notice/shipping-notice.vue'
        ),
      meta: {
        title: 'route.shippingNotice',
        id: 954
      }
    },
    {
      path: '/consume-management',
      name: 'ConsumeManagement',
      component: async () =>
        import(
          /* webpackChunkName: "consume-management" */ '@/views/production-management/consume-management/consume-management.vue'
        ),
      meta: {
        title: 'route.consumeManagement',
        id: 1699
      }
    }
  ],
  meta: {
    title: 'route.productionManagement',
    icon: '#production',
    id: 991
  }
};
