import { BindProcessEnum, ResourceUrlEnum } from '@/resource/enum';
import { DepartmentResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Query } from '../base';
import { CascaderOption } from 'element-ui/types/cascader';
import { translation } from '@/utils';

class DepartmentService extends BaseService<DepartmentResource> {
  public getById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<DepartmentResource>(ResourceUrlEnum.department, { params: { id } })
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Query<DepartmentResource>): Promise<Array<DepartmentResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DepartmentResource>>(`${ResourceUrlEnum.department}/list`, query)
        .then((res: ApiResponse<Array<DepartmentResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: DepartmentResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<DepartmentResource>(ResourceUrlEnum.department, resource)
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: DepartmentResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DepartmentResource>(ResourceUrlEnum.department, resource)
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.department, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查部门名称是否重复
   * @param depName 部门名称
   * @param id 部门主键
   * @returns 为true则代表重复
   */
  public checkDepartmentName(depName: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.department}/checkDepName`, {
          params: { id, depName }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有启用状态的部门
   * @returns 所有启用状态的部门
   */
  public getAllDepartments(): Promise<Array<DepartmentResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DepartmentResource>>(`${ResourceUrlEnum.department}/listDepartment`)
        .then((res: ApiResponse<Array<DepartmentResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public handleCascaderOption(departments: Array<DepartmentResource>): Array<CascaderOption> {
    return departments.map(x => {
      const option: CascaderOption = {
        label: x.depName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }

  /**
   * 获取岗位工序
   * @returns
   */
  public getBindProcessSelectableList(): Array<any> {
    const list = [];
    for (const key in BindProcessEnum) {
      if (!isNaN(Number(key)) && Number(key)) {
        list.push({
          label: translation(`bindProcessName.${BindProcessEnum[key]}`),
          value: Number(key)
        });
      }
    }

    return list;
  }
  /**
   * 绑定工序
   * @param resource
   * @returns
   */
  public bindProcess(resource: DepartmentResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DepartmentResource>(`${ResourceUrlEnum.department}/bindProcess`, resource)
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getProcessTypeId(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<DepartmentResource>(`${ResourceUrlEnum.department}/findProcessTypeById`, { params: { id } })
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const departmentService = new DepartmentService();
export default departmentService;
