/**
 * 项次类型
 */
export enum ProjectItemTypeEnum {
  /**
   * 产成品
   */
  finishedProduct = 1,
  /**
   * 改色
   */
  changeColor
}
