import { render, staticRenderFns } from "./mini-message-center.vue?vue&type=template&id=515fd723&scoped=true&"
import script from "./mini-message-center.ts?vue&type=script&lang=ts&"
export * from "./mini-message-center.ts?vue&type=script&lang=ts&"
import style0 from "./mini-message-center.scoped.scss?vue&type=style&index=0&id=515fd723&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515fd723",
  null
  
)

export default component.exports