/**
 * 导入模板在minio上的地址
 */
export enum ImportTemplateEnum {
  /**
   * 人员
   */
  personnel = 'user_template.xlsx',
  /**
   * 产品
   */
  product = 'product_template.xlsx',
  /**
   * 输出
   */
  output = 'crifts_template.xlsx',
  /**
   * 后道
   */
  backend = 'crifts_template.xlsx',
  /**
   * 物流发货模板
   */
  logisticsDelivery = 'logistics_order_template.xlsx',
  /**
   * 物流单模板
   */
  logistics = 'delivery_notice_import_logistics_template.xlsx'
}
