import { systemConfigService } from '@/api';
import { Region, RegionQuery } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { ElCalendar } from 'element-ui/types/calendar';
import { CascaderNode, CascaderProps, Resolve } from 'element-ui/types/cascader-panel';
import { Component, Emit, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class OsAddress extends Vue {
  @ModelSync('address', 'change', { type: Array })
  public selectedAddress!: Array<string>;

  @Prop({ required: false, default: true, type: Boolean })
  public clearable!: boolean;

  @Prop({ required: false, default: true, type: Boolean })
  public filterable!: boolean;

  @Prop({ required: false, type: String })
  public size!: 'medium' | 'small' | 'mini';

  @Prop({ required: false, default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ required: false, default: translation('components.selectRegionTip'), type: String })
  public placeholder!: string;

  @Prop({
    required: false,
    default: () => {
      return {};
    },
    type: Object
  })
  public props!: CascaderProps<string, Region>;

  public options: Array<Region> = [];

  /**
   * 首次数据复显是否结束，加载到叶子节点时即代表复显结束
   * 为了解决三级或以下选中都能复显的问题
   */
  public reloaded = false;

  public defaultProps!: CascaderProps<string, Region>;

  private key = true;

  public get osAddress(): ElCalendar {
    return this.$refs.osAddress as ElCalendar;
  }

  public created(): void {
    // 合并props
    this.defaultProps = Object.assign(this.props, {
      lazy: true,
      label: 'name',
      value: 'name',
      lazyLoad: async (node: CascaderNode<string, Region>, resolve: Resolve<Region>): Promise<void> => {
        try {
          const query: RegionQuery = node.path
            ? {
                province: node.path[0],
                city: node.path[1]
              }
            : {};
          const res = await systemConfigService.getSystemRegion(query);
          let trigger = false;
          resolve(
            res.map(x => {
              if (node.level === (this.selectedAddress?.length ?? 3) - 1 && !this.reloaded) {
                x.leaf = true;
                trigger = true;
              } else if (node.level === 2) {
                x.leaf = true;
              }
              return x;
            })
          );
          this.reloaded = trigger;
        } catch (error) {
          messageError(error);
        }
      }
    });
  }

  public reload(): void {
    this.key = !this.key;
  }

  @Emit()
  public change(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public beforeFilter(value: string): boolean | Promise<any> {
    return Promise.resolve();
  }
}
