import { Component, Prop, Vue } from 'vue-property-decorator';
import { OsTableColumn } from '../os-table/os-table';
import OsTableCustom from '../os-table-custom/os-table-custom.vue';
import { ElButton } from 'element-ui/types/button';
export interface OperationOption extends Partial<ElButton> {
  /**
   * 操作类型，用于标识按钮的唯一性
   */
  operationType:
    | 'add'
    | 'delete'
    | 'using'
    | 'disabled'
    | 'import'
    | 'export'
    | 'repair'
    | 'confirm'
    | string;
  /**
   * 按钮显示的文本
   * 请保证按钮文本和图标至少有一项
   */
  label: string;
  /**
   * 按钮的图标
   */
  icon?: string;
  /**
   * 按钮显示的位置
   */
  slot: 'start' | 'end';
  /**
   * 权限标识
   */
  permissionCode?: string;
  handleClick: () => void;
  /**
   * 是否动态隐藏该操作按钮,不传则默认显示按钮
   * true则隐藏
   */
  dynamicHidden?: () => boolean;
}
@Component({
  name: 'os-table-operation',
  components: {
    OsTableCustom
  }
})
export default class OsTableOperation extends Vue {
  @Prop({ type: Array, required: true }) public operationOptions!: Array<OperationOption>;

  @Prop({ type: Array, required: false }) public customColumns?: Array<OsTableColumn<any>>;

  public get startPosition(): Array<OperationOption> {
    return this.operationOptions.filter(x => x.slot === 'start');
  }

  public get endPosition(): Array<OperationOption> {
    return this.operationOptions.filter(x => x.slot === 'end');
  }

  private columnChanged(columns: Array<OsTableColumn<any>>): void {
    this.$emit('column-change', columns);
  }

  private columnReset(): void {
    this.$emit('column-reset');
  }
}
