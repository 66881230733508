/**
 * 空值过滤器
 * @param value 要处理的值
 * @param replaceValue 为空时要显示的内容
 * @returns 过滤后的值
 */
export const emptyValue = (value: any, replaceValue = '--'): any => {
  if (typeof value === 'number') {
    return value;
  }
  if (!value || value.length === 0) {
    return replaceValue;
  }
  return value;
};
