export const enum ResourceUrlEnum {
  minio = 'minIo',
  /**
   * 用户中心
   */
  userCenter = 'user/center',
  /**
   * 登录认证模块
   */
  auth = 'auth',
  /**
   * 部门
   */
  department = 'organize/department',
  /**
   * 角色
   */
  role = 'system/role',
  /**
   * 岗位
   */
  position = 'organize/position',
  /**
   * 人员管理
   */
  personnel = 'organize/user',
  /**
   * 产品/材料分类
   */
  productClassify = 'product/material/category',
  /**
   * 产品
   */
  product = 'product',
  /**
   * 输出材料
   */
  printingMaterial = 'product/printing/material',
  /**
   * 后道材料
   */
  backendMaterial = 'product/backend/material',
  /**
   * 设备
   */
  device = 'system/device',
  /**
   * 后道工艺
   */
  backendCraft = 'system/crafts/backend/list',
  /**
   * 输出工艺
   */
  outputCraft = 'system/crafts/printing/list',
  /**
   * 设备分类
   */
  deviceClassify = 'system/device/category',
  /**
   * 平台产品
   */
  platformProduct = 'platform/product',
  /**
   * 生产订单
   */
  productionOrder = 'production/order',
  /**
   * 生产订单详情
   */
  productionOrderProduct = 'production/orderItem',
  /**
   * 生产订单变更单
   */
  productionOrderChange = 'production/change',
  /**
   * 客户
   */
  customer = 'customer',
  /**
   * 订单门店
   */
  productionOrderShop = 'production/shop',
  /**
   * 全部单据
   */
  receipt = '/order/item/all',
  /**
   * 印前单据
   */
  prepressReceipt = '/order/item/prepress',
  /**
   * 输出单据
   */
  printingReceipt = '/order/item/print',
  /**
   * 后道单据
   */
  backendReceipt = '/order/item/backend',
  /**
   * 报损
   */
  productBroken = '/production/report',
  /**
   * 发件人
   */
  sender = 'system/sender/template',
  /**
   * 打印机
   */
  printer = 'system/printer',
  /**
   * 发货通知单
   */
  deliveryNotice = 'delivery/notice',
  // 电子面单模板
  electronicExpressWaybill = 'system/express/e/template',
  /**
   * 物流单
   */
  logistics = 'logistics/order',
  /**
   * lodop端口设置
   */
  lodopPort = 'system/lodop',
  /**
   * 消息中心
   */
  messageCenter = 'message/center',
  /**
   * 耗损管理
   */
  consume = 'production/attrition',
  /**
   * 消息配置
   */
  messageConfig = 'message/scene',
  /**
   * 数据字典
   */
  dataDictionary = 'base/dict',
  /**
   * 省市区
   */
  region = 'system/region',
  /**
   * 仪表盘
   */
  dashboard = 'dashboard',

  /**
   * 查询方案
   */
  queryPlan = 'system/query/template',
  /**
   * 建议反馈
   */
  feedback = 'feedback',

  /**
   * 单文件上传接口
   */
  upload = 'file/upload',
  /**
   * 多文件上传接口
   */
  batchUpload = 'file/batchUpload'
}
