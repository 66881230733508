/**
 * 单据操作类型
 */
export enum OperationTypeEnum {
    /**
     * 印前
     */
    prepress = 1,
    /**
     * 输出
     */
    print = 2,
    /**
     * 后道
     */
    backend = 3
}