import { customerService } from '@/api';
import { CustomerResource } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectCustomer extends Vue {
  @ModelSync('customerId', 'change', { type: [Number, String] })
  public selectedCustomerId!: number;

  @Prop({ type: String, required: false, default: 'components.selectCustomer' })
  public placeholder!: string;

  public customers: Array<CustomerResource> = [];

  public created(): void {
    this.getCustomers();
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
