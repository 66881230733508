/**
 * 印前排版工艺
 */
enum PrepressLayoutCraftsEnum {
  /**
   * 原档输出
   */
  noneAdjustmentToPrint = 1,
  /**
   * 调整尺寸
   */
  dimensionAdjustment,
  /**
   * 按guideline排版
   */
  accordingToGuideline,
  /**
   * 比例档制作
   */
  ratioLayout,
  /**
   * 外发文件
   */
  printReadyFile,
  /**
   * 像素排版
   */
  pixelLayout,
  /**
   * 其他
   */
  others = 11
}

/**
 * 印前改色工艺
 */
enum PrepressChangeColorCraftsEnum {
  /**
   * 原档输出
   */
  noneAdjustmentToPrint = 1,
  /**
   *  依色对稿
   */
  accordingToTheProof = 7,
  /**
   * 修图补图
   */
  graphicRetouch,
  /**
   * 漂亮美观
   */
  commonSense,
  /**
   * 屏幕改色
   */
  softPrinting,
  /**
   * 其他
   */
  others = 11
}

export { PrepressLayoutCraftsEnum, PrepressChangeColorCraftsEnum };
