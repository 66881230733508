import { UserModule } from '@/store/modules/user';
import { Form } from 'element-ui/types/element-ui';
import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { MessageBox } from 'element-ui';
import { translation } from '@/utils';
import { encryptAes } from '@/utils/cryption';
import Verify from '../verifition/verify.vue';

@Component({
  name: 'mini-login',
  components: { Verify }
})
export default class MiniLogin extends Vue {
  public loginForm = {
    username: '',
    password: '',
    domain: ''
  };

  public loginRules = {
    username: [
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('login.emptyUsername') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    password: [
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('login.emptyPassword') as string));
          } else if (value.length < 8) {
            callback(new Error(this.$t('login.passwordLength') as string));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }
    ],
    domain: [
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('login.emptyDomain') as string));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }
    ]
  };

  public loading = false;

  public get miniLoginVisible(): boolean {
    return UserModule.openMiniLoginPage;
  }

  public dialogOpen(): void {
    this.loginForm.username = UserModule.account;
  }

  public async success(checkRes: { loginCode: string }): Promise<void> {
    this.loading = true;
    const passwordEncrypted = encryptAes(`${this.loginForm.password}@-login-@${checkRes.loginCode}`);
    UserModule.Login({
      username: this.loginForm.username,
      password: passwordEncrypted,
      domain: this.loginForm.domain
    })
      .then(() => {
        // 若登录失效后，重新登录的账户跟之前的不一致，则在登录成功重定向回首页，并重新加载用户以及权限信息
        if (UserModule.account !== this.loginForm.username) {
          UserModule.setReLoading(true);
          this.$router.push({ path: '/' });
          return;
        }
        MessageBox.confirm(translation('tip.confirmReload'), translation('tip.confirmTitle'), {
          confirmButtonText: translation('button.ok'),
          cancelButtonText: translation('button.cancel')
        })
          .then(() => {
            location.reload();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: translation('tip.stayCurrentPage')
            });
          });
      })
      .catch()
      .finally(() => {
        this.loading = false;
      });
  }

  public handleLogin(): void {
    (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return false;
      }
      UserModule.setAccountInfo({ account: this.loginForm.username, domainName: this.loginForm.domain });
      (this.$refs.verify as any).show();
    });
  }

  public dialogClosed(): void {
    (this.$refs.loginForm as Form).resetFields();
  }
}
