export default {
  route: {
    login: 'Login',
    dashboard: 'Dashboard',
    personalSetting: 'Personnel setting',
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    i18n: 'I18n',
    profile: 'Profile',
    systemSetting: 'System setting',
    organization: 'Organization',
    department: 'Department management',
    personnel: 'Personnel management',
    position: 'Position management',
    role: 'Role management',
    messageConfig: 'Message config',
    device: 'Device management',
    product: 'Product management',
    productList: 'Product list',
    output: 'Output craft',
    backend: 'Backend craft',
    printingMaterial: 'Printing material',
    backendMaterial: 'Backend material',
    // 生产管理
    productionManagement: 'Production Management',
    productionOrder: 'Order List',
    productionOrderChange: 'Order Change Log',
    productionOrderDetail: 'Order Detail',
    addProductionOrderChange: 'New Order Change Log',
    shippingNotice: 'Shipping Notice',
    addShippingNotice: 'New Shipping Notice',
    addProductionOrder: 'New Production Order',
    distributeOrder: 'Add Order',
    productionOrderChangeDetail: 'Production Order Change Detail',
    // 单据管理
    receiptManagement: 'Receipt Management',
    allReceiptList: 'All',
    prepressReceiptList: 'Prepress',
    printingReceiptList: 'Printing',
    backendReceiptList: 'Backend',
    productionOrderShopDetail: 'Shop Details',
    sender: 'sender management',
    messageCenter: 'Message center',
    printer: 'Printer management',
    printPreview: 'Print preview',
    nothingPrintList: 'Nothing to print documents ',
    // 物流管理
    logisticsManagement: 'Logistics management',
    logisticsDetails: 'Logistics details',
    addLogistics: 'Add logistics',
    logistics: 'Logistics list',
    deliveryNotice: 'Delivery notice list',
    deliveryNoticeDetails: 'Delivery notice details',
    addDeliveryNotice: 'Delivery notice',
    electronicExpressWaybill: 'Electronic express waybill',
    // 系统设置
    systemConfig: 'System parameter setting',
    // 报损管理
    consumeManagement: 'Consume management',
    // 反馈建议
    feedbackSuggestion: 'Feedback Suggestion',
    feedbackSetting: 'Feedback Setting',
    feedbackSuggestionList: 'Feedback Suggestion List',
    addFeedbackSuggestion: 'Submit Feedback Suggestion'
  },
  common: {
    status: 'Status',
    selectStatus: 'Please select status',
    createTime: 'Create time',
    operation: 'Operation',
    menu: 'Menu',
    button: 'Button',
    startUsing: 'Using',
    disabled: 'Disabled',
    keyword: 'Keyword',
    inputKeyWord: 'Please enter key words',
    createUser: 'Creator',
    usingSuccess: 'Using successful',
    disabledSuccess: 'Disabled successful',
    man: 'Man',
    woman: 'Woman',
    remark: 'Remark',
    unKnownStatus: 'UnKnown Status',
    yuan: 'yuan',
    repair: 'Repairing',
    statusUpdateStatus: 'Status updated',
    createUserName: 'Creator',
    inputCreateUserName: 'Please enter create user name',
    startPlaceholder: 'Begin Date',
    endPlaceholder: 'End Date',
    moreOpetations: 'More operations',
    inputRemark: 'Please fill in the remarks',
    waitingUpload: 'Waiting upload',
    clickToPreview: 'Click to preview',
    all: 'All',
    select: 'Please select',
    yes: 'Yes',
    no: 'No',
    download: 'Download',
    index: 'Index'
  },
  navbar: {
    logout: 'Logout',
    dashboard: 'Dashboard',
    profile: 'Profile'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  table: {
    loadingText: 'loading...',
    summaryText: 'Sum'
  },
  button: {
    cancel: 'Cancel',
    ok: 'Ok',
    submit: 'Submit',
    add: 'Add',
    search: 'Search',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    using: 'Using',
    disabled: 'Disabled',
    import: 'Import',
    select: 'Select',
    confirmSelect: 'Confirm select',
    confirmImport: 'Confirm import',
    packUp: 'Pack up',
    more: 'More',
    unfold: 'Unfold',
    fold: 'Fold',
    checkAll: 'Check all',
    clearAll: 'Clear all',
    back: 'Back',
    export: 'Export',
    repairing: 'Repairing',
    download: 'Download',
    batchCancel: 'Batch Cancel',
    batchDelete: 'Batch Delete',
    confirmComplete: 'Confirm Complete',
    distributeOrder: 'Distribute',
    nextStep: 'Next',
    prevStep: 'Prev',
    submitAndSend: 'Submit And Send',
    submitApproval: 'Submit Approval',
    approval: 'Approval',
    accept: 'Accept',
    reject: 'Reject',
    exportDetail: 'Export Details',
    take: 'Take',
    finish: 'Finish',
    save: 'Save',
    close: 'Close',
    bindProcess: 'Binding process'
  },
  operationRes: {
    cancelDelete: 'Canceled delete',
    addSuccess: 'Added successfully',
    editSuccess: 'Edited successfully',
    deleteSuccess: 'Deleted successfully',
    addFailed: 'Failed to add',
    editFailed: 'Fail to edit',
    deleteFailed: 'Failed to delete',
    cancelReset: 'Cancel reset',
    resetSuccess: 'Reset success',
    operationCanceled: 'Operation Canceled',
    cancelOperation: 'Operation cancelled',
    operationSuccess: 'Operation success',
    saveSuccess: 'Save success',
    operationFailed: 'Operation failed'
  },
  tip: {
    tipInfo: 'prompt message',
    closeTip: 'Are you sure you want to close it?',
    confirmDelete: 'Are you sure you want to delete the selected data?',
    resetPassword: 'Make sure to reset user password to',
    confirmTitle: 'Confirmation message',
    confirmReload: 'Please confirm whether you need to reload the page information?',
    stayCurrentPage: 'Stay on the current page',
    confirmCancel: 'Are you sure to cancel?',
    selectReqirements: 'Please select the data that meets the requirements!'
  },
  components: {
    changeLanguageTips: 'Switch Language Success',
    tagRepeat: 'Tag repeat',
    selectRegionTip: 'Please select region',
    selectCustomer: 'Please select a customer'
  },
  datePicker: {
    startPlaceholder: 'Begin date',
    endPlaceholder: 'End date'
  },
  minio: {
    initError: 'The service is temporarily unavailable. Please check the network status and try again!',
    bucketNotExist: 'Template file not found, please contact the administrator!'
  },
  lodop: {
    printUnInstallTip:
      'You have not installed the print control, click download the print control, and after successful installation, refresh the page to print. ',
    loading: 'The print control is not ready yet, please try again later!'
  },
  dialog: {
    selectPosition: 'Select position',
    selectPersonnel: 'Select personnel',
    importExceed: 'You can select at most one file',
    dragUpload: 'Drag the file here, or',
    clickUpload: ' click on the upload',
    downloadTemplate: 'Download the import template',
    maxUploadSizeTip: 'The maximum file size is ',
    acceptExtensionTip: 'Supported extension:',
    importClassify: 'Import classify',
    importLoadingText: 'loading...',
    importSuccess: 'Import success',
    importErrorTip: 'The failure message is as follows:',
    importErrorRow: 'Number of failed lines：',
    importErrorReason: 'Reasons for failure：',
    importPlatformProduct: 'Import platform product',
    importCraft: 'Import craft',
    importCustomer: 'Import customer ',
    importInvoice: 'Import invoice info',
    importTaxClassify: 'Import tax classification',
    importCustomerShop: 'Import customer shop',
    importCustomerPoint: 'Import shop data',
    importSupplier: 'Import supplier data'
  },
  validate: {
    mobileIllegal: 'The phone number is illegal',
    inputLegalTel: 'Please fill in a legal phone number'
  },
  requestError: {
    // 公共异常
    unknownError: 'System error',
    namePepeat: 'Name repeat',
    tokenVerifyError: 'Authentication failed',
    tokenExpired: 'Login information is invalid. Please log in again!',
    requireParameter: 'Parameter cannot be empty',
    invalidParameter: 'Parameter is invalid',
    importFailed: 'Data import failed',
    nameRepeat: 'The name already exists',
    fileNoData: 'No data in file',
    exportNoData: 'There is no data to export',
    downloadFailed: 'File download failed',
    fileReadFailed: 'File read failed',
    timeout: 'Timeout',
    enterpriseInfoNot: 'Enterprise information does not exist',
    // 登录模块
    nameOrPassError: 'Incorrect user name or password',
    forbiddenAcccount: 'The account has been disabled',
    forbiddenCompany: 'The company has been banned',
    nameNotExist: 'Account is invalid',
    noPermission: 'No permission to operate',
    userNotLogin: 'Login information is invalid. Please log in again!',
    // 找回密码模块
    sendVerifyCodeError: 'Failed to send a verification code',
    emailFormatError: 'The mailbox format does not conform to the specification',
    emailNorrelated: 'The email address has not been linked to any account',
    companyNotExist: 'Company domain name does not exist',
    resetPasswordError: 'Password reset failed',
    passwordFormatError: 'The password format does not meet the requirements',
    passwordDecryptError: 'Password decryption failure',
    // 部门管理
    hasPosition: 'Job data has been bound',
    departmentNameRepeat: 'Duplicate Department Name',
    parentDepartmentNoExit: 'The superior department does not exist',
    hasChildDepartment:
      'There are subordinate departments in this department. Please delete subordinate departments first',
    // 角色管理
    roleNameRepeat: 'The role name already exists',
    roleIsUsing: 'This role has been used in the position',
    // 岗位管理
    positionIsUsing: 'Delete failed, there is a user bound to the post',
    positionNameRepeat: 'The position name already exists',
    // 人员管理
    userNameRepeat: 'The login account already exists',
    emailRepeat: 'The email already exists',
    mobileRepeat: 'The phone number already exists',
    classifyNameRepeat: 'The classify name already exists',
    // 修改密码
    passwordError: 'Password error',
    // 供应商材料
    materialNameRepeat: 'Material name has been used',
    materialCodeRepeat: 'Material code has been used',
    // 供应商设备
    deviceNameRepeat: 'Device name has been used',
    // 供应商产品
    printingCraftCanNotUse: 'The printing craft can not use',
    printingMaterialCanNotUse: 'The printing material can not use',
    backendMaterialCanNotUse: 'The backend material can not use',
    backendCraftCanNotUse: 'The backend craft can not use',
    productNameRepeat: 'The product name already exists',
    // 发货通知单
    unDelivery: 'Please deliver the goods first',
    hasLogisticOrderCanNotCancel:
      'Cancel failed! There is a logistics order under the shipping notice, please delete the relevant logistics order first',
    notCancelFinish: 'The status of the selected delivery notice does not meet the requirements.',
    // 生产订单
    productionOrderExist: 'Production order created',
    productionOrderNotExist: 'Production order not exist',
    productionOrderStatusNotNew: 'Production order status not 「New」',
    productionOrderStatusNotWaitConfirm: 'Production order status not 「Waiting confirm」',
    productionOrderStatusNotWaitAccept: 'Production order status not 「Waiting accept」',
    productionOrderStatusNotWaitSchedule: 'Production order status not 「Waiting schedule」',
    productionOrderStatusNotInProgress: 'Production order status not 「In progress」',
    productCountOver: 'Product distribution count over than available count',
    productionOrderDetailNotExist: 'Production order detail not exist',
    // 生产订单变更单
    existChangeLog: 'The order already have change log which not execution, do not create repeat',
    changeLogCanNotPush: 'Change log can not to push, because the status not 「new」',
    changeLogCanNotConfirm: 'Change log can not to confirm, because the status not 「waiting confirm」',
    canNotChangeComplatedOrder:
      'The production order is already in "Pending" or "Completed" status and cannot be changed',
    changeLogNotExist: 'Change log not exist',
    changeLogNoDetail: 'Change log detail not exist',
    supplierProductNotExist: 'Change product not exist',
    changeProductNotExist: 'Change product does not exist',
    billInProduction: 'Order are being prepared',
    sentDownItem: 'Item has flowed down',
    // 单据管理
    orderItemTakeError: 'Receipts take error',
    orderItemFinishError: 'Receipts finish error',
    receivingStatusUnMatched: 'Documents have not reached the receiving stage!',
    canNotReturnNotOpenProcess: 'Return process not open',
    prepressUnfinished: 'Prepress unfinished',
    platformgPrepressNotOpen: 'Platform before printing did not open it',
    loyoutNotFound: 'There is no layout documents',
    orderItemExistChangeOrder: 'Change order exists',
    // 物流
    orderNotReadyForDelivery: 'The document is not ready for shipment',
    logisticsOrderSender: 'Shipper is empty',
    logisticsOrderSenderTel: 'The contact information of the shipper is empty',
    logisticsOrderNo: 'The logistics order number does not exist',
    logisticsOrderExpressName: 'The logistics company does not exist',
    orderNotclose: 'The current state is not allowed to close',
    // 反馈建议
    feedbackTimeout: 'The submission failed to exceed the time interval',
    feedbackCloseSubmit: 'The submission permission has been closed. Please contact the administrator!',
    feedbackSubmitExceed: "Today's submission has exceeded the upper limit"
  },
  httpRequestError: {
    oftenRequest: 'Requesting too often',
    otherError: 'Abnormal request',
    unAuthorized: 'Unauthorized, please authenticate your identity',
    serverDeniedAccess: 'Server denied access',
    resourceNotFound: 'Resources not found',
    methodDisabled: 'The request method is disabled',
    timeout: 'The request timeout',
    serverError: 'Server internal error',
    serverNotSupport: 'The server does not have the capability to complete the request',
    networkError: 'The request timeout',
    serveDied: 'Service unavailable',
    neworkTimeout: 'Network timeout',
    httpVersionNotSupport: 'The HTTP version is not supported',
    httpCanceled: 'The request has been cancelled'
  },
  accountSetting: {
    basicSetting: 'Basic setting',
    securitySetting: 'Security setting',
    email: 'Email',
    inputEmail: 'Please fill in the email address',
    phone: 'Phone',
    inputPhone: 'Please fill in the contact number',
    avatar: 'Avatar',
    replaceAvatar: 'Replace avatar',
    updateInfo: 'Update information',
    password: 'Account password',
    avatarUploadError: 'Failed to upload avatar'
  },
  messageCenter: {
    notice: 'Notice',
    todo: 'Todo',
    noNotice: 'No notice',
    noTodo: 'No todo'
  },
  login: {
    title: 'Supplier Login',
    reLoginTitle: 'Login again',
    logIn: 'Login',
    username: 'Please fill in the user name',
    password: 'Please fill in the password',
    forgetPassword: 'Forget password',
    rememberPassword: 'Remember password',
    emptyUsername: 'Please enter the correct user name',
    emptyPassword: 'Please enter the correct password',
    passwordLength: 'The password can not be less than 8 digits',
    verifyExplain: 'Swipe to the right to verify',
    verifyTitle: 'Please complete the security verification',
    getVerifyCodeFailed: 'Failed to get the captcha',
    verifySuccessfully: 'verify successfully',
    verifyFailed: 'Verification failed',
    resetPasswordTitle: 'Reset password',
    domain: 'Please fill in the domain',
    emptyDomain: 'The company domain cannot be empty'
  },
  resetPassword: {
    domain: 'Enterprise domain',
    enterDomain: 'Please fill in your domain name',
    domainEmpty: 'The company domain cannot be empty',
    email: 'Email',
    verifyCode: 'Verification code',
    password: 'New password',
    checkPassword: 'Confirm password',
    sendVerifyCode: 'Send',
    enterEmail: 'Please enter the email address',
    enterVerifyCode: 'Verification code',
    enterPassword: 'Please fill in a new password',
    enterCheckPassword: 'Please confirm the new password',
    emailEmpty: 'Please fill in the email',
    emailError: 'The email address is not valid',
    verifyCodeEmpty: 'Please fill in the verification code',
    passwordEmpty: 'Please fill in your password.',
    passwordError: 'The password format does not meet the requirements',
    checkPasswordEmpty: 'Please enter your password again',
    passwordNotEqual: 'The two passwords do not match',
    resetSuccess: 'Reset success',
    passwordRules: 'Password rules',
    passwordIntegrant: 'The password consists of English, numbers and special characters',
    passwordLength: 'Passwords should be 8-20 characters long',
    passwordMustBe: 'Must contain uppercase letters, special characters'
  },
  department: {
    departmentName: 'Department name',
    sort: 'Sort',
    inputSort: 'Please enter display sorting, such as 1,2,3',
    sortEmpty: 'Display sort is not allowed to be empty',
    parentDepartment: 'Superior department',
    selectParentDepartment: 'Please select superior department',
    inputDepartmentName: 'Please enter department name',
    nameEmpty: 'The department name cannot be blank',
    nameSpace: 'Department names cannot contain Spaces',
    nameRepeat: 'The department name already exists',
    addDepartment: 'New department',
    editDepartment: 'Edit department',
    department: 'Department',
    selectDepartment: 'Please Select Department',
    selectProcess: 'Please Select Deparment'
  },
  role: {
    addRole: 'New role',
    editRole: 'Edit role',
    name: 'Role name',
    code: 'Role code',
    inputName: 'Please enter a role name',
    inputRemark: 'Please enter remark information',
    nameEmpty: 'The role name cannot be empty',
    permissionAllot: 'Permission allot',
    selectPermission: 'Select the permissions that the role can have',
    role: 'Role',
    selectRole: 'Please select a role',
    permissionConfig: 'Access config'
  },
  position: {
    addPosition: 'Add position',
    editPosition: 'Edit position',
    parentName: 'Higher position',
    name: 'Position name',
    code: 'Position code',
    affiliationDepartment: 'Department',
    selectAffiliationDepartment: 'Please select department',
    inputName: 'Please fill in the position name',
    selectParentPosition: 'Please select a superior position',
    position: 'Position',
    selectPosition: 'Please select position',
    topLevel: 'Top level'
  },
  personnel: {
    addPersonnel: 'Add personnel',
    editPersonnel: 'Edit personnel',
    userName: 'User name',
    realName: 'Real name',
    code: 'User code',
    positonName: 'Position name',
    email: 'Email',
    mobile: 'Mobile',
    inputUserName: 'Please fill in your login account',
    inputRealName: 'Please fill in your real name',
    userNameRepeat: 'The login account already exists',
    emailRepeat: 'Email already exists',
    mobileRepeat: 'The phone number already exists',
    inputEmail: 'Please fill in the email',
    inputMobile: 'Please fill in your phone number',
    selectPosition: 'Please select positon',
    pisition: 'Position(multiple)',
    gender: 'Gender',
    initPassword: 'The user initial password is 123456',
    importPersonnel: 'Import personnel'
  },
  classify: {
    code: 'Classify code',
    name: 'Classify name',
    packageType: 'Packaging',
    addClassify: 'Add classify',
    editClassify: 'Edit classify',
    tiled: 'tiled',
    reel: 'reel',
    inputName: 'Please fill in the classify name'
  },
  deviceClassify: {
    addDeviceClassify: 'Add device classify',
    editDeviceClassify: 'Edit device classify'
  },
  user: {
    editPassword: 'Change password'
  },
  supplierMaterial: {
    code: 'Code',
    name: 'Name',
    category: 'Category',
    brand: 'Brand',
    size: 'Size',
    unit: 'Unit',
    displayOrder: 'Display order',
    import: 'Import Material',
    selectCategory: 'Please select category',
    inputName: 'Please fill name',
    inputCode: 'Please fil code',
    inputBrand: 'Please fill brand',
    selectUnit: 'Please select unit',
    inputWidth: 'Please fill Width',
    inputLength: 'Please fill Length',
    inputDisplayOrder: 'Please fill display order',
    inputRemark: 'Please fill remark',
    addMaterial: 'Add material',
    editMaterial: 'Edit material',
    exportPrintingMaterial: 'Export output material',
    exportBackendMaterial: 'Export backend material',
    printingTemplateName: 'printing_import_template.xlsx',
    backendTemplateName: 'backend_import_template.xlsx'
  },
  materialUnit: {
    meter: 'Meter',
    centimetre: 'Centimetre',
    millimetre: 'Millimetre'
  },
  materialType: {
    printing: 'Printing',
    backend: 'Backend'
  },
  supplierDevice: {
    deviceName: 'Name',
    categoryName: 'Category',
    printHead: 'Print Head',
    colorInfo: 'Color Info',
    category: 'Category',
    selectCategory: 'Please select category',
    deviceTemplate: 'device_template.xlsx',
    inputDeviceName: 'Please fill device name',
    inputPrintHead: 'Please fill print head info',
    inputColorInfo: 'Please fill color info',
    inputRemark: 'Please fill remark',
    addDevice: 'Add Device',
    editDevice: 'Edit Device',
    importDevice: 'Import device'
  },
  product: {
    templateName: 'supplier_product_template.xlsx',
    import: 'Import Product',
    productCode: 'Product Code',
    categoryName: 'Category',
    platformProductName: 'Platform Product',
    productName: 'Product Name',
    price: 'Price',
    priceUnit: 'Unit',
    weight: 'weight',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    selectCategoryName: 'Please select category',
    selectPlatformProduct: 'Please select platform product',
    inputPrice: 'Please fill price',
    selectPriceUnit: 'Pleasee select unit',
    inputWeight: 'Please fill weight',
    selectPrintingCraft: 'Please select printing craft',
    selectPrintingMaterial: 'Please select printing mateiral',
    selectBackendCraft: 'Please select backend craft',
    selectBackendMaterial: 'Please select backend material',
    printingCraft: 'Printing Craft',
    printingMaterial: 'Printing Material',
    backendCraft: 'Backend Craft',
    backendMaterial: 'Backend Material',
    inputRemark: 'Please input remark'
  },
  chargeUnit: {
    squareMeter: 'Square meter',
    piece: 'Piece',
    cover: 'Suit',
    individual: 'One'
  },
  order: {
    orderStatus: 'Status',
    customer: 'Customer',
    createUserName: 'Creator',
    createDate: 'Create Date',
    selectStatus: 'Please select status(multiple)',
    selectCustomer: 'Please select customer',
    startPlaceholder: 'Begin Date',
    endPlaceholder: 'End Date',
    code: 'Order Code',
    projectName: 'Project Name',
    customerName: 'Customer',
    supplierName: 'Supplier',
    progress: 'Progress',
    createTime: 'Create Time',
    batchDistribute: 'Batch Distribute',
    new: 'New',
    waitingAccept: 'Waiting Accept',
    waitingPlan: 'Waiting Plan',
    inProgress: 'Processing',
    waitingConfirm: 'Waiting Confirm',
    completed: 'Completed',
    confirmDistribute: 'Confirm distribute selected order?',
    distributeSuccess: 'Distribute success',
    distributeCancel: 'Distribute cancel',
    selectNewOrderTip: 'Please select [New] status order',
    selectWaitingAccpetOrderTip: 'Please select [Waiting accept] status order',
    confirmCancel: 'Confirm to cancel selected orders?',
    cancelSuccess: 'Order cancel success',
    orderCompleted: 'Order completed',
    requiredDeliveryTime: 'Required Delivery',
    requiredArriveTime: 'Required Arrive',
    remark: 'Remark',
    orderAmount: 'Amount',
    productList: 'Product List',
    shipmentList: 'Shipment List',
    goOrderList: 'Go List',
    createAgain: 'Create again',
    createSuccessText: 'Order Create Success',
    selectSupplier: 'Select Supplier',
    pleaseUploadFile: 'Please Upload File',
    inputRemark: 'Please enter remark',
    online: 'Online',
    offline: 'Offline',
    orderDetail: 'Detail File',
    productionFile: 'Production File',
    oneKeyImport: 'Import All',
    selectProduct: 'Select Products',
    relatedProduct: 'Related Product',
    inputBase: 'Base',
    distributionProduct: 'Select Products',
    createSuccess: 'Created',
    pleaseDistributeProduct: 'Please select products',
    pleaseSelectSupplierProduct: "Please choose supplier product for item '{0}'",
    batchRelateProducts: 'Batch Relate Products',
    selectSupplierProduct: 'Please select supplier product',
    selectCustomProduct: 'Please select customer product',
    editRelateProducts: 'Edit Relate Product',
    productTotalCount: 'Product Count',
    totalArea: 'Area(㎡)',
    cardArea: 'Area',
    acceptUserName: 'Accept User',
    finishTime: 'Finish Time',
    confirmProduction: 'Confirm Plan',
    submitComplete: 'Submit Finish',
    submitCompleteTip: 'Are you sure to submit order complete?',
    confirmProductionTip: 'Are you sure to confirm order production?',
    acceptTip: 'Are you sure to accept the order?',
    rejectTip: 'Are you sure to reject the order?',
    inputReason: 'Please enter reject reason',
    shopList: 'Shop List',
    selectInProgressTip: 'Only can submit orde which status is in progress',
    downloadFile: 'Downloads',
    cancel: 'Cancel',
    cancelTip: 'Are you sure to cancel the order?',
    materialClassStatistics: 'Material Class Statistics',
    urgentOrder: 'Whether Urgent Order'
  },
  orderProduct: {
    itemCode: 'Item code',
    itemType: 'Item type',
    status: 'Item status',
    pointName: 'Point',
    platformProductName: 'Product',
    customProductName: 'Customer Product',
    supplierProductName: 'Supplier Product',
    count: 'Count',
    totalArea: 'Area(㎡)',
    visibleWidth: 'Visible Width(mm)',
    visibleHeight: 'Visible Height(mm)',
    completeWidth: 'Complete Width(mm)',
    completeHeight: 'Complete Height(mm)',
    detailVisibleSize: 'Visible Size',
    detailCompleteSize: 'Complete Size',
    prepressPicture: 'Prepress Picture',
    prepressDescription: 'Pre Press Description',
    remark: 'Remark',
    requiredDeliveryTime: 'Required Delivery Time',
    requiredArriveTime: 'Required Arrive Time',
    currentProcess: 'Current Process',
    finishTime: 'Finish Time',
    brokenLog: 'Broken Log',
    noProductionNeed: 'No production need',
    prepress: 'Prepress',
    printing: 'Printing',
    backend: 'Backend',
    purchasePrice: 'Price',
    salePrice: 'Price',
    chargeUnit: 'Unit',
    visibleSize: 'Visible Size(mm)',
    completeSize: 'Complete Size(mm)',
    productArea: 'Area',
    detailTitle: 'Product Detail',
    baseInfo: 'Base',
    progress: 'Progress',
    backendCrafts: 'Backend Crafts',
    shopName: 'Shop Name',
    prepressFlag: 'Prepress',
    printingFlag: 'Printing',
    backendFlag: 'Backend',
    customerName: 'Customer',
    projectName: 'Project',
    takeUserName: 'Taker',
    takeTime: 'Take time',
    productName: 'Product',
    prepressCrafts: 'Prepress crafts',
    prepressConfirmFile: 'Preview',
    clickToPreviewPDF: 'Click to preview PDF',
    createUserName: 'Creator',
    keywords: 'Code / Product name',
    isConsumeRemake: 'Consume remake',
    flagOpen: 'Open',
    flagClose: 'Close'
  },
  orderShop: {
    shopCode: 'Code',
    shopName: 'Name',
    productionProgress: 'Production Progress',
    logisticsProgress: 'Logistics Progress',
    address: 'Address',
    contact: 'Contact',
    shopManager: 'Manager',
    receiver: 'Receiver',
    receiverTel: 'Telephone',
    upload: 'Upload Image',
    selectArea: 'Select Area',
    projectName: 'Project',
    customerName: 'Customer',
    orderCode: 'Order',
    productList: 'Product List'
  },
  /**
   * 工序状态
   */
  processStatus: {
    new: 'New',
    waiting: 'Waiting',
    processing: 'Processing',
    completed: 'Finished',
    closed: 'No Need'
  },
  /**
   * layout单打印状态
   */
  layoutStatus: {
    notGenerate: 'notGenerate',
    generate: 'generate'
  },
  overtimeStatusEnum: {
    notOvertime: 'Not overtime',
    willOvertime: 'Will overtime',
    overtime: 'Overtime'
  },
  /**
   * 订单项次产品状态
   */
  orderProductStatus: {
    waitPlan: 'Waiting plan',
    new: 'Waiting production',
    processing: 'Processing',
    completed: 'Finished',
    canceled: 'Canceled'
  },
  orderProductType: {
    product: 'Product',
    sample: 'Sample',
    time: 'Time Service'
  },
  processActionEnum: {
    take: 'Take',
    finish: 'Finish'
  },
  operationTypeEnum: {
    prepress: 'Prepress',
    print: 'Print',
    backend: 'Backend'
  },
  brokenLog: {
    brokenType: 'Broken Type',
    brokenProcess: 'Process',
    count: 'Count',
    priceUnit: 'Unit',
    area: 'Area(m²)',
    reason: 'Reason',
    dutyUser: 'Duty User',
    remark: 'Remark',
    completeSize: 'Complete Size(mm)',
    createUserName: 'Creator',
    createTime: 'Create Time',
    orderChangeLogCode: 'Related Order Change Log',
    brokenItem: 'Broken item'
  },
  orderLogisticsOrder: {
    code: 'Shipping Code',
    shippingType: 'Shipping Type',
    shippingCompany: 'Company',
    shippingCode: 'Code',
    status: 'Status',
    receiveAddress: 'Address',
    receiver: 'Receiver',
    contact: 'Contact',
    receiveTime: 'Recive Time',
    sendAddress: 'Address',
    sender: 'Sender',
    sendTime: 'Send Time',
    receiveInfo: 'Receive ',
    sendInfo: 'Send',
    logisticsInfo: 'Logistics',
    count: 'Count',
    remark: 'Remark',
    parentOrder: 'Parent Order'
  },
  shippingType: {
    express: 'Express',
    selfPick: 'Self Pick',
    carDelivery: 'Car Delivery'
  },
  logisticsOrderStatus: {
    new: 'Waiting delivery goods',
    receivedGoods: 'Received goods',
    inTransit: 'In transit',
    error: 'Exception',
    received: 'Received',
    closed: 'closed'
  },
  logisticsStatus: {
    new: 'Waiting delivery goods',
    toBeCollect: 'To be collect',
    inTransit: 'In transit',
    dispatch: 'Dispatch',
    received: 'Received',
    back: 'Give back',
    signBack: 'Sign back',
    conversion: 'Conversion',
    diffcult: 'Difficult',
    customsClearance: 'Customs clearance',
    refusalToSign: 'Refusal to sign'
  },
  orderChange: {
    changeDate: 'Change Date',
    changeTime: 'Change Time',
    changeMan: 'Change man',
    code: 'Code',
    orderCode: 'Order Code',
    projectName: 'Project Name',
    customerName: 'Customer',
    supplierName: 'Supplier',
    status: 'Status',
    changeReason: 'Change reason',
    selectNewOrFailed: 'Please choose which new or approval failed records',
    selectPendingApproval: 'Please choose pending approval or pending confirm status record',
    submitApprovalSucces: 'Submit Success',
    submitApproval: 'Submit Approval',
    selectStatus: 'Select status',
    approval: 'Approval',
    approvalDescription: 'Description',
    inputApprovalDescription: 'Please enter the description',
    approverName: 'Approver',
    approvalTime: 'Approval time',
    changeDetail: 'Change Details',
    confirmTime: 'Confirm time',
    changeConfirm: 'Change Confirm',
    confirmTip: "Please click 'code' and confirm change on detail page",
    submitConfirmTip: 'Please confirm change content?',
    reject: 'Rejected',
    reviewRejected: 'Review Rejected',
    refuseReason: 'Refuse Reason',
    rejectReason: 'Reject reason',
    inputRefuseReason: 'Please enter refuse reason'
  },
  orderChangeStatus: {
    new: 'New',
    pendingApproval: 'Auditing',
    approvalFailed: 'Approval Failed',
    pendingConfirm: 'Pending Confirm',
    reject: 'rejected',
    finished: 'Finished'
  },
  changeType: {
    edit: 'Modify',
    minusCount: 'Minus',
    stopProduction: 'Stop',
    addProduct: 'New Product',
    addCount: 'Increase'
  },
  orderChangeDetail: {
    changeType: 'Change Type'
  },
  receipt: {
    status: 'Status',
    customer: 'Customer',
    orderCode: 'Production order code',
    inputKeyWord: 'Receipt Code / Order Code',
    selectCustomer: 'Please select customer',
    createDate: 'Create Date',
    export: 'Receipt Details',
    itemCode: 'Code',
    brokenReport: 'Broken Report',
    timeout: 'Timeout',
    timeoutComingUp: 'Timeout coming up',
    selectReceipts: 'Please select pending and platform prepress is completed records ',
    finishReceiptsTip: 'Are you sure to finish selected receipts?',
    selectProcessingReceipts: 'Please select processing records',
    takeDialogTitle: 'Take Receipts',
    device: 'Device',
    selectDevice: 'Select Device',
    selectDeviceTip: 'Please select device',
    takeReceiptsTip: 'Are you sure to take selected receipts?',
    deliveryDate: 'Delivery date',
    screenArea: 'Screen area(㎡)',
    screenRate: 'Screen rate',
    totalArea: 'Total area',
    platformPrepressStatus: 'Platform prepress status',
    prepressStatus: 'Prepress status',
    printStatus: 'Prepress status',
    backendStatus: 'Backend status',
    platformPrepressUnDoneTip:
      'The platform has not been completed before printing and cannot accept orders!',
    edited: 'The receipt has been modified!',
    selectItemType: 'Please select the order type',
    inputProductName: 'Please enter product name',
    inputPrepressPicture: 'Please enter prepress picture',
    productionFile: 'Production file',
    thumbnails: 'Screen preview',
    preview: 'Preview',
    notUpload: 'Not upload',
    printCount: 'Print count',
    inputprintCount: 'please input print count',
    selectPrintReceipts: 'The selected data has a problem， please choose again',
    printLayout: 'Print documentst',
    recreateLayout: 'Update Layout Documents',
    limitExceeded: 'Up to 100 prints at a time',
    layoutStatus: 'Layout Status',
    isDepletion: 'Loss or not'
  },
  productBroken: {
    itemCode: 'Item Code',
    backProcess: 'Back process',
    brokenType: 'Broken Type',
    dialogTitle: 'Broken report',
    unit: 'Unit',
    selectUnit: 'Please select unit',
    inputCount: 'Please enter count',
    inputRemark: 'Please enter remark',
    dutyUser: 'Duty user',
    inputDutyUser: 'Please enter duty user',
    count: 'Count',
    dutyReason: 'Duty reason',
    inputDutyReason: 'Please enter duty reason',
    selectBrokenType: 'Please select broken type',
    consumedNumIllegal: 'The amount of loss cannot be greater than the total number of products',
    brokenArea: 'Broken area(㎡)',
    inputBrokenArea: 'Please fill in the broken area',
    consumeStage: 'Consume stage',
    dutyAgencyType: 'Duty agency type',
    consumeInfo: 'Consume info'
  },
  sender: {
    addSender: 'New sender',
    editSender: 'Edit sender',
    senderName: 'Sender name',
    inputName: 'Please fill in the sender name',
    senderNumber: 'Contact number',
    inputNumber: 'Please fill in the contact number',
    area: 'Ship area',
    selectArea: 'Please select the ship area',
    address: 'Ship Address',
    inputAddress: 'Please fill in the full ship Address'
  },
  printer: {
    addPrinter: 'Add printer',
    editPrinter: 'Edit printer',
    name: 'Printer name',
    inputName: 'Please fill in the printer name',
    model: 'Printer model',
    inputModel: 'Please fill in the printer model',
    ip: 'Printer ip',
    inputIP: 'Enter the printer IP address',
    port: 'Printer port',
    inputPort: 'Please enter the printer port number'
  },
  deliveryDetails: {
    code: 'Code: ',
    selectSupplier: 'Please select supplier',
    relatedProjects: 'Related project: ',
    relatedCustomer: 'Related customer: ',
    shopDetails: 'Shop details',
    productDetails: 'Product details',
    customSite: 'Custom site',
    addSite: 'Add site',
    importDeliveryDetails: 'Import delivery details',
    selectProduct: 'Select production',
    deliverySite: 'Delivery site',
    deliveryAddress: 'Delivery address',
    receiver: 'Receiver',
    receiverTel: 'Receiver tel',
    supplier: 'supplier: ',
    deliveryRemark: 'Delivery remark: ',
    remark: 'Remark: ',
    inputReceiver: 'Please fill in the receiver',
    inputReceiverTel: 'Please fill in the receiver telephone number',
    area: 'Area',
    selectArea: 'Please select local area',
    address: 'Address',
    inputAddress: 'Please fill in the full address',
    addDeliverySite: 'Add delivery point',
    editDeliverySite: 'Edit delivery point',
    shopName: 'Delivery point',
    inputShopName: 'Please fill in the delivery point',
    sendCount: 'Quantity shipped',
    selectProductItem: 'Select product item',
    productClassify: 'Product classify',
    platformProduct: 'Platform product',
    customerProduct: 'Customer product',
    projectProductKey: 'Support by item code, point name',
    addDeliveryNotice: 'Add delivery notice',
    editDeliveryNotice: 'Edit delivery notice',
    remainDeliveryCount:
      'The delivered quantity has exceeded the total number of products in the item, the remaining deliverable quantity of the item is',
    remainCount: 'Remain count',
    posterTemplateName: 'poster_delivery_details',
    popTemplateName: 'pop_delivery_details',
    importLogisticsTitle: 'Import logistics bill',
    exportPosterDetails: 'Export poster details',
    exportPopDetails: 'Export pop details',
    logisticsOrderTotal: 'Logistics order total',
    isCreatedLogistics: 'Is created logistic order',
    plannedShippingTime: 'Planned shipping time'
  },
  projectProduct: {
    itemCode: 'Item code',
    customerName: 'customerName',
    productionProgress: 'Production progress',
    pointName: 'Point name',
    consumed: 'Consumed',
    status: 'Status',
    finishTime: 'Finish time',
    consumedCode: 'Consumed item code',
    copy: 'Copy',
    templateName: 'product_data_import_template',
    keywords: 'Item code or product name are supported',
    itemType: 'Item type',
    finishedProduct: 'Finished product',
    inputPointName: 'Please fill in the name of the point',
    visibleSize: 'Visible size(mm)',
    finishSize: 'Finish size(mm)',
    platformProduct: 'Platform product',
    customerProduct: 'Customer product',
    salePrice: 'Sale price',
    priceUnit: 'Price unit',
    backendCrafts: 'Backend crafts',
    requiredDeliveryTime: 'Delivery time',
    requiredArriveTime: 'Arrive time',
    count: 'Item count',
    deliveryNumber: 'Delivery number',
    remark: 'Remark',
    prepressPicture: 'Prepress picture',
    isOverdue: 'Is Overdue',
    toBeTimeout: 'Will be overdue'
  },
  deliveryNoticeStatus: {
    new: 'New',
    waitTakeOrder: 'Wait take order',
    waitDelivery: 'Wait delivery',
    partDelivery: 'Part delivery',
    delivered: 'Delivered',
    complete: 'Complete'
  },
  deliveryNoticeList: {
    code: 'Code',
    status: 'Status',
    customer: 'Customer',
    orderCreateTime: 'Order create time',
    supplier: 'Supplier',
    project: 'Project',
    deliveryRemark: 'Delivery remark',
    selectCustomer: 'Please select select customer',
    selectStatus: 'Please select document status',
    selectOrderCreateTime: 'Please select your order time',
    batchPush: 'Batch Push',
    batchCancel: 'Batch Cancel',
    inputCode: 'Please fill in the code',
    deliveryConfirm: 'Delivery confirm',
    deliveryComplete: 'Delivery complete ',
    isConfirm:
      'Are you sure you want to confirm the selected delivery notice? (Only documents to be "wait take order" are supported)',
    onlyWaitTakeOrder:
      'The status of the selected delivery notice does not meet the requirements. Please select the delivery notice with "new" status!',
    isComplete:
      'Are you sure you want to complete the selected delivery notice? (Only documents to be "wait delivery" are supported)',
    onlyWaitDelivery:
      'The status of the selected delivery notice does not meet the requirements. Please select the delivery notice with "wait delivery" status!',
    requiredDeliveryTime: 'Required delivery time',
    requiredArriveTime: 'Required arrive time',
    exportMaxTip: 'You can select a maximum of one delivery notice when exporting details',
    shopTotal: 'Shop total',
    itemTotal: 'Item total',
    cancelTakeOrderTip: 'Are you sure you want to cancel the order?',
    cancelComplete: 'Cancel Complete',
    cancelFinish:
      'Are you sure you want to cancel finish the selected delivery notice? (Only documents to be "finished" are supported)',
    onlyComplete:
      'The status of the selected finished notice does not meet the requirements. Please select the delivery notice with "finished" status!'
  },
  electronicExpressWaybill: {
    add: 'Add electronic express waybill template',
    edit: 'Edit electronic express waybill template',
    name: 'Name',
    inputName: 'Please fill in the name',
    expressCompany: 'Express company',
    selectExpressCompany: 'Please select the express company',
    expressType: 'Express type',
    selectExpressType: 'Please select the express type',
    templateStyle: 'Template style',
    selectTemplate: 'Please select the template style',
    account: 'Account',
    inputAccount: 'Please fill in the monthly account',
    password: 'password',
    inputPassword: 'Please fill in the monthly password',
    receiptShops: 'Receipt shops',
    inputReceiptShops: 'Please fill in the receipt shops',
    paymentMode: 'Payment mode',
    selectPaymentMode: 'Please select the payment mode',
    printer: 'Associated printer',
    selectPrinter: 'Please select the associated printer',
    isDefault: 'Whether to set it to default',
    deliveryFeePrepaid: 'Delivery fee prepaid',
    deliveryFeeToBeCollected: 'Delivery fee to be collected',
    deliveryFeeMonthly: 'Delivery fee monthly',
    yes: 'Yes',
    no: 'No',
    partnerSecret: 'Partner secret',
    inputPartnerSecret: 'Please fill in the partner secret'
  },
  deliveryMode: {
    TPL: 'TPL',
    specialTrain: 'Special train',
    selfPickUp: 'Self pick up'
  },
  logistics: {
    add: 'Add logistics',
    selectDelivertMode: 'Please choose the delivery mode',
    inputShopCount: 'Please fill in the order of each shop',
    inputDeliveryRemark: 'Please fill in the delivery remarks',
    deliveryMode: 'Delivery mode',
    shopCount: 'Shop count',
    shippingInfo: 'Shipping information',
    deliveryRemark: 'Delivery remark',
    logisticsInfo: 'Logistics info',
    expressCompany: 'Logistics company',
    logisticsNo: 'Logistics No',
    receiverInfo: 'Receiving info',
    senderInfo: 'Delivery info',
    subscribeStatus: 'Subscribe status',
    subscribeState: 'Subscribe state',
    code: 'Bill code',
    inputCode: 'Please enter bill code',
    relevanceDeliveryBill: 'Relevance delivery bill',
    relevanceCustomer: 'Relevance customer',
    relevanceShop: 'Relevance shop',
    relevanceProject: 'Relevance project',
    childCount: 'Parcel count',
    inputChildCount: 'please enter parcel count',
    status: 'Logistics order status',
    logisticsStatus: 'Logistics status',
    newLogisticsTrack: 'The latest track',
    selectCustomer: 'Please select associate customer',
    seelctOrderStatus: 'Please select logistics order status',
    seelctStatus: 'Please select logistics status',
    delivery: 'Delivery',
    importDelivery: 'Import delivery',
    receiving: 'Receiving',
    waybillPrint: 'Print waybill',
    batchChangeCost: 'Batch change cost',
    changeInfo: 'Change info',
    export: 'Export',
    subscribeTrack: 'Subscribe track',
    subscribeSuccess: 'Subscribe success',
    unSubscribe: 'Un subscribe',
    confirmDelivery: 'Please confirm whether to ship',
    confirmReceiving:
      'Are you sure you want to receive the selected documents?（Only documents in the "wait for receiving" status are supported）',
    confirmDelete:
      'Are you sure you want to delete the selected documents?（Only documents in the "wait delivery" status are supported）',
    deliveryTime: 'Delivery time',
    receivingTime: 'Receiving time',
    templateName: 'logistics_delivery_import_template',
    downloadFileName: 'logistics',
    logisticsCost: 'Logistics cost',
    weight: 'Weight(KG)',
    price: 'Price(Yuan)',
    inputCost: 'Please fill in the logistics cost',
    inputWeight: 'Please fill in the weight',
    batchSet: 'Batch set',
    arriveTime: 'Arrive time',
    inTransit: 'In transit',
    logisticsTrack: 'Logistics track',
    mapMode: 'Map mode',
    deliveryUser: 'Delivery user',
    selectDeliveryUser: 'Please select delivery user',
    electronicBill: 'Electronic bill',
    selectElectronicBill: 'Please select electronic bill',
    selectRequiredArriveTime: 'Please select required arrive time ',
    confirmPrint: 'Order successful! Do you need to print the electronic sheet immediately',
    takeElectronicBill: 'Electronic bill order',
    startGotTime: 'Schedule a pickup time',
    selectStartGotTime: 'Please select schedule a pickup time',
    cancleDelivery: 'Cancel delivery',
    cancelDeliveryTip:
      'Are you sure you want to cancel the shipment? (Only documents whose logistics order status is waiting for pick-up are supported)',
    noLogisticsTrack: 'No relevant logistics track information has been queried yet',
    importFare: 'Import fare',
    importFareTips:
      'The header of the freight import excel file must include the logistics number, weight, and freight, and the header must be placed on the second line!！',
    receivingCity: 'Receiving city',
    inputReceivingCity: 'Please enter receiving city',
    selectExpressCompany: 'Please select a logistics company',
    selectDeliveryMode: 'Please select shipping method',
    shopName: 'Shop name',
    inputShopName: 'Please enter shop name',
    productList: 'Product list',
    requiredArriveTime: 'Required arrive time',
    arrivalTime: 'Arrival time',
    batchCloseTitle: 'Close Documents',
    closeReason: 'Close reason',
    inputCloseReason: 'please enter close reason'
  },
  systemConfig: {
    lodopPort: 'Print control port',
    inputLodopPort: 'Please fill in the port number of the print control',
    lodopPortTip:
      'Before using the printer, please ensure that the printer control is installed. After installation, fill in the print control port according to the actual situation and save it'
  },
  projectItemType: {
    finishedProduct: 'Finished product',
    changeColor: 'Sample'
  },
  prepressLayoutCrafts: {
    noneAdjustmentToPrint: 'None adjustment to print',
    dimensionAdjustment: 'Dimension adjustment',
    accordingToGuideline: 'According to guideline',
    ratioLayout: 'Ratio Llyout',
    printReadyFile: 'Print ready file',
    pixelLayout: 'Pixel layout',
    others: 'Others'
  },
  prepressChangeColorCrafts: {
    pixelLayout: 'Pixel layout',
    accordingToTheProof: 'According to the proof',
    graphicRetouch: 'Graphic retouch',
    commonSense: 'Common sense',
    softPrinting: 'Soft printing',
    others: 'Others'
  },
  consume: {
    export: 'consume_file',
    createUserName: 'Create user name',
    createTime: 'Create time',
    inputPlatformProduct: 'Please fill in the production name',
    inputItemCode: 'Please fill in the item code',
    consumeStage: 'Consume stage',
    selectConsumeStage: 'Please select consume stage',
    dutyAgencyType: 'Duty agency type',
    selectDutyAgencyType: 'Please select the type of duty agency',
    dutyUser: 'Duty user',
    inputDutyUser: 'Please fill in the duty user',
    consumeType: 'Consume type'
  },
  prepressStatus: {
    new: 'Wait for production',
    waitForProduction: 'Wait for production',
    inProduction: 'In production',
    waitForConfirm: 'Wait for confirm',
    waitComplete: 'Wait complete',
    completed: 'Completed',
    consumed: 'Consumed'
  },
  platformPrepressStatus: {
    notMade: 'Not made',
    waitForProduction: 'Wait for production',
    inProduction: 'In production',
    completed: 'Completed'
  },
  dutyAgencyType: {
    platform: 'Platform',
    productionSupplier: 'Production supplier',
    materialSupplier: 'Material supplier',
    logisticsSupplier: 'Logistics supplier',
    installSupplier: 'Install supplier'
  },
  dashboard: {
    todo: 'Todo',
    today: 'Today',
    thisWeek: 'Week',
    thisMonth: 'Month'
  },
  productionTodo: {
    toBeConfirmChange: 'To Be Confirm Change',
    toBeReceiveProduced: 'To BeReceive Produced',
    toBeScheduling: 'To Be Scheduling'
  },
  logisticTodo: {
    errorLogisticsOrder: 'Error Logistics Order',
    overdueLogisticsOrder: 'Overdue LogisticsOrder',
    overdueNotReceived: 'Overdue Not Received',
    toBeReceiveShip: 'To Be Receive Ship',
    toBeShip: 'To Be Ship'
  },
  productionReport: {
    title: 'Production summary',
    productionArea: 'Production area',
    inProductionArea: 'In production area',
    waitPrintArea: 'Wait print area',
    waitBackendArea: 'Wait backend area',
    consumedArea: 'Consumed area',
    proofingArea: 'Proofing area'
  },
  productDeliveryReport: {
    title: 'Order product area statistics (unit: m²)',
    inputProjectCode: 'Please fill in the project code',
    deliveryDateName: 'Shipment Date/Product Name',
    number: 'Number',
    area: 'Area',
    checkProjectCode: 'Please check that the project code is correct',
    titleTip:
      'Statistics of unfinished production documents in accepted orders based on order number and delivery date range'
  },
  //  异常播报
  exceptionReport: {
    title: 'Exception report',
    logisticsExcept: 'Logistics except',
    slippageUnsigned: 'Slippage unsigned',
    slippageWarning: 'Slippage warning'
  },
  feedback: {
    title: 'Feedbanck Suggestion',
    code: 'Feedback code',
    content: 'Feedback Content',
    type: 'Feedback type',
    create: 'Submission date',
    source: 'Feedback channel',
    createUserName: 'Submitter',
    createTime: 'Submission time',
    inputContent: 'Please enter feedback content',
    selectType: 'Please select feedback type',
    enclosure: 'Enclosure',
    details: 'Feedback details'
  }
};
