import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
@Component({
  name: 'language-select'
})
export default class languageSelect extends Vue {
  public get language(): string {
    return AppModule.language;
  }

  private handleSetLanguage(lang: string): void {
    this.$i18n.locale = lang;
    AppModule.SetLanguage(lang);
    document.documentElement.lang = lang;
    // 切换语言后，还要在这里切换浏览器tab的title语言
    // const title = this.$route.meta.title
    //   ? `${this.$t(`route.${this.$route.meta.title}`)} - ${settings.title}`
    //   : `${settings.title}`
    // document.title = title
    this.$message({
      message: this.$t('components.changeLanguageTips').toString(),
      type: 'success'
    });
  }
}
