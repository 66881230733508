import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import Hamburger from '@/components/hamburger/hamburger.vue';
import LanguageSelect from '@/components/language-select/language-select.vue';
import MiniMessageCenter from '@/views/mine/message-center/mini-message-center/mini-message-center.vue';
import { MessageCenterModule } from '@/store/modules/message';
@Component({
  name: 'nav-bar',
  components: {
    Breadcrumb,
    Hamburger,
    LanguageSelect,
    MiniMessageCenter
  }
})
export default class NavBar extends Vue {
  public popover = false;

  public get sidebar(): { opened: boolean; withoutAnimation: boolean } {
    return AppModule.sidebar;
  }

  public get device(): string {
    return AppModule.device.toString();
  }

  public get avatar(): string {
    return UserModule.avatar;
  }

  public get userName(): string {
    return UserModule.name;
  }

  public get unReadMessageTotal(): number {
    return MessageCenterModule.unReadTotal;
  }

  private toggleSideBar(): void {
    AppModule.ToggleSideBar(false);
  }

  private async logout(): Promise<void> {
    UserModule.Logout()
      .then(() => {
        this.$router.push(`/login`).catch(err => {
          console.warn(err);
        });
      })
      .catch(() => {});
  }
}
