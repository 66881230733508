export enum LogisticsStatusEnum {
  /**
   * 待发货
   */
  new = 100,
  /**
   * 揽收
   */
  toBeCollect = 1,
  /**
   * 在途
   */
  inTransit = 0,
  /**
   * 派件
   */
  dispatch = 5,
  /**
   * 签收
   */
  received = 3,
  /**
   * 退回
   */
  back = 6,
  /**
   * 退签
   */
  signBack = 4,
  /**
   * 转投
   */
  conversion = 7,
  /**
   * 疑难
   */
  diffcult = 2,
  /**
   * 清关
   */
  customsClearance = 8,
  /**
   * 拒签
   */
  refusalToSign = 14
}
