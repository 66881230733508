/**
 * 材料类型
 */
export enum MaterialTypeEnum {
  /**
   * 输出
   */
  printing = 1,
  /**
   * 后道
   */
  backend = 2
}
