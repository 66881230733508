import { Input } from 'element-ui/types/element-ui';
import { ElInput } from 'element-ui/types/input';
import { ElTag } from 'element-ui/types/tag';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

type PartialElTag = {
  [P in keyof ElTag]?: ElTag[P];
};
type PartialElInput = {
  [P in keyof ElInput]?: ElInput[P];
};
export interface OsTagOptions extends PartialElTag {
  /**
   * 是否禁用修改
   */
  disabledUpdate?: boolean;
  /**
   * 是否禁用创建
   */
  disabledCreate?: boolean;
  /**
   * 组件尺寸（包含tag标签的展示尺寸，和input的尺寸）
   */
  size: 'large' | 'medium' | 'small' | 'mini';
  /**
   * 标签最大输入长度
   */
  tagMaxlength: number;
}
export type OsTagInputOptions = PartialElInput;
@Component({
  name: 'os-tags'
})
export default class OsTags extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: (): OsTagOptions => {
      return {
        closable: true,
        disableTransitions: true,
        size: 'small',
        tagMaxlength: 20
      };
    }
  })
  public tagOptions!: OsTagOptions;

  @Prop({
    type: Array,
    required: true,
    default: () => {
      return [];
    }
  })
  public tags!: Array<string>;

  /**
   * 本组件不允许出现重复的标签，若输入重复标签，此属性会返回标签重复
   */
  @PropSync('error', { type: String, required: false, default: '' })
  public inputError!: string;

  public updateValue = '';

  /**
   * 是否显示创建标签的输入框
   */
  public createInputVisible = false;
  /**
   * 添加标签时输入框填写的内容
   */
  public createValue = '';

  private updateInputWidth = 0;

  private viewTags: Array<{
    value: string;
    /**
     * 是否显示编辑标签的输入框
     */
    inputVisible: boolean;
  }> = [];

  public showUpdateInput(index: number): void {
    const tagNode: Array<ElTag> = this.$refs[`tag-${this.viewTags[index].value}`] as Array<ElTag>;
    if (tagNode && tagNode.length === 1) {
      this.updateInputWidth = (tagNode[0].$vnode.elm as HTMLElement)?.offsetWidth;
    }
    this.viewTags[index].inputVisible = true;
    this.$nextTick(() => {
      this.updateValue = this.viewTags[index].value;
      const inputNode = this.$refs[`updateInput-${this.viewTags[index].value}`] as Array<ElInput>;
      if (inputNode && inputNode.length === 1) {
        (inputNode[0].$refs.input as Input).focus();
      }
    });
  }

  public showCreateInput(): void {
    this.createInputVisible = true;
    this.$nextTick(() => {
      ((this.$refs.tagCreateInput as ElInput).$refs.input as Input).focus();
    });
  }

  public addTag(): void {
    if (!this.createValue) {
      this.createInputVisible = false;
      this.inputError = '';
      return;
    }
    if (this.checkTagValueRepeat(this.createValue)) {
      return;
    }
    this.viewTags.push({
      value: this.createValue,
      inputVisible: false
    });
    this.createInputVisible = false;
    this.createValue = '';
    this.emitTag();
  }

  public updateTag(index: number): void {
    const viewTag = this.viewTags[index];
    if (!viewTag) {
      return;
    }
    // 如果修改内容为空或者修改内容未发生变化，则不执行赋值操作，并关闭输入框
    if (!this.updateValue || this.updateValue === viewTag.value) {
      this.updateValue = '';
      this.inputError = '';
      viewTag.inputVisible = false;
      return;
    }
    if (this.checkTagValueRepeat(this.updateValue)) {
      return;
    }
    viewTag.value = this.updateValue;
    viewTag.inputVisible = false;
    this.updateValue = '';
    this.emitTag();
  }

  public deleteTag(index: number): void {
    this.viewTags.splice(index, 1);
    this.emitTag();
  }

  private get getUpdateInputWidth(): string {
    return this.updateInputWidth !== 0 ? `${this.updateInputWidth + 20}px` : 'auto';
  }

  /**
   * 检查标签值是否重复
   * @param tagValue 标签值
   * @returns 重复时返回true
   */
  private checkTagValueRepeat(tagValue: string): boolean {
    this.inputError = '';
    if (this.viewTags.find(x => x.value === tagValue)) {
      this.$nextTick(() => {
        this.inputError = this.$t('components.tagRepeat') as string;
      });
      return true;
    }
    return false;
  }

  @Watch('tags', { immediate: true })
  private initTags(tags: Array<string>): void {
    this.createValue = '';
    this.createInputVisible = false;
    this.viewTags = tags.map(x => {
      return {
        value: x,
        inputVisible: false
      };
    });
  }

  private emitTag(): void {
    const tags = this.viewTags.map(x => x.value);
    this.$emit('change', tags);
  }
}
