import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { ResourceUrlEnum } from '@/resource/enum';
import { MessageResource } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from './base';

class MessageCenterService {
  public getNotices(paging: Paging): Promise<ApiResponse<Array<MessageResource>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<MessageResource>>(`${ResourceUrlEnum.messageCenter}/listPageNotice`, paging)
        .then((res: ApiResponse<Array<MessageResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getTodos(paging: Paging): Promise<ApiResponse<Array<MessageResource>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<MessageResource>>(`${ResourceUrlEnum.messageCenter}/listPageTodo`, paging)
        .then((res: ApiResponse<Array<MessageResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getUnReadNotices(): Promise<Array<MessageResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<MessageResource>>(`${ResourceUrlEnum.messageCenter}/unreadNotice`)
        .then((res: ApiResponse<Array<MessageResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getUnReadTodos(): Promise<Array<MessageResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<MessageResource>>(`${ResourceUrlEnum.messageCenter}/unreadTodo`)
        .then((res: ApiResponse<Array<MessageResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 设置已读状态
   * @param id
   * @returns
   */
  public setReadStatus(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.messageCenter}/read`, null, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取未读的所有消息数量（包括通知和待办两种类型）
   * @param id
   * @returns
   */
  public getUnReadTotal(): Promise<number> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<number>(`${ResourceUrlEnum.messageCenter}/countUnread`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const messageCenterService = new MessageCenterService();
export default messageCenterService;
