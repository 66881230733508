import { ResourceUrlEnum } from '@/resource/enum';
import {
  DeliveryProductSummary,
  LogisticsList,
  LogisticsListQuery,
  LogisticTodoReport,
  ProductionReport,
  ProductionTodoReport
} from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

export class DashboardService {
  /**
   * 获取物流待办汇总
   * @param params
   * @returns
   */
  public static getlogisticTodoReport(): Promise<LogisticTodoReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<LogisticTodoReport>(`${ResourceUrlEnum.dashboard}/logistics/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取生产待办汇总
   * @param params
   * @returns
   */
  public static getProductionTodoReport(): Promise<ProductionTodoReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionTodoReport>(`${ResourceUrlEnum.dashboard}/production/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取生产汇总
   * @param params
   * @returns
   */
  public static getProductionReport(params: {
    customerId?: number;
    startTime: string;
    endTime: string;
  }): Promise<ProductionReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ProductionReport>(`${ResourceUrlEnum.dashboard}/production/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取生产产品汇总
   * @param params
   * @returns
   */
  public static getProductReport(params: {
    code?: string;
    startTime: string;
    endTime: string;
  }): Promise<Array<DeliveryProductSummary>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeliveryProductSummary>>(`${ResourceUrlEnum.dashboard}/product/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 物流异常
   * @param query
   * @param paging
   * @returns
   */
  public static getErrorList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/error`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 逾期未签收
   * @param query
   * @param paging
   * @returns
   */
  public static getTimeoutList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/timeout`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 逾期预警
   * @param query
   * @param paging
   * @returns
   */
  public static getWarningList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/timeoutWarn`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
