import { ImportRes } from '@/resource/model';
import i18n from '@/lang';
import { ResourceStatusEnum, ErrorLevelEnum } from '@/resource/enum';
import { Message, MessageBox, Notification } from 'element-ui';
import { ElMessageBoxOptions, MessageBoxData } from 'element-ui/types/message-box';
import { ApiError, ApiResponse } from '@/api/axios';
import { getErrorObj } from './handle-request-error';
import moment from 'moment';
import { AxiosResponse } from 'axios';

export const translation = (key: string): string => {
  return i18n.t(key) as string;
};
export const showWarningConfirm = (message: string): Promise<MessageBoxData> => {
  return MessageBox.confirm(message, translation('tip.tipInfo'), {
    confirmButtonText: translation('button.ok'),
    cancelButtonText: translation('button.cancel'),
    type: 'warning'
  });
};

export const showWarningPrompt = (
  message: string,
  placeholder: string,
  options: ElMessageBoxOptions
): Promise<MessageBoxData> => {
  const defaultOptions: ElMessageBoxOptions = {
    inputPlaceholder: placeholder,
    confirmButtonText: translation('button.ok'),
    cancelButtonText: translation('button.cancel'),
    type: 'warning',
    inputValidator: (value: string): boolean => {
      return !!value;
    }
  };

  return MessageBox.prompt(message, translation('tip.tipInfo'), Object.assign(defaultOptions, options));
};
/**
 * 返回对应状态的i18n key
 * @param status 状态
 * @returns
 */
export const getStatusI18Key = (status: ResourceStatusEnum): string => {
  let res;
  switch (status) {
    case ResourceStatusEnum.using:
      res = 'common.startUsing';
      break;
    case ResourceStatusEnum.disabled:
      res = 'common.disabled';
      break;
    default:
      res = 'common.unKnownStatus';
      break;
  }
  return res;
};

/**
 * 返回对应状态的class名称
 * @param status 状态
 * @returns
 */
export const getStatusClass = (status: ResourceStatusEnum): string => {
  return status === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
};

export const handleImportError = (error: ApiResponse<ImportRes>): void => {
  if (!error.data) {
    // 处理读取文件时出现的错误
    const errorObj = getErrorObj(error.code);
    messageError(errorObj);
    return;
  }
  let errorMessage = '';
  error.data.errorList.forEach((x, index) => {
    errorMessage += `<span>${index + 1}、${i18n.t('dialog.importErrorRow')}
    ${x.rowNum}，${i18n.t('dialog.importErrorReason')}<br /></span><span>${x.errMsg}</span><br />`;
  });
  Notification.error({
    title: error.message,
    duration: 0,
    dangerouslyUseHTMLString: true,
    message: `<span>${i18n.t('dialog.importErrorTip')}</span><br />${errorMessage}`
  });
};

/**
 * 通过远程路径下载文件
 * @param name 下载文件的文件名
 * @param path 下载路径
 */
export const downloadFileByPath = (name: string, path: string): void => {
  fetch(path).then(response => {
    response.blob().then(myBlob => {
      const href = URL.createObjectURL(myBlob);

      const eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.style.display = 'none';
      eleLink.href = href;
      document.body.appendChild(eleLink);
      // 触发点击
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);

      // 释放由 createObjectURL 创建的 object URL
      URL.revokeObjectURL(eleLink.href);
    });
  });
};

/**
 * 通过远程超链接下载文件
 * @param name 下载文件的文件名
 * @param path 下载路径
 */
export const downloadFileByPremoteLink = (link: string): void => {
  const eleLink = document.createElement('a');
  eleLink.style.display = 'none';
  eleLink.href = link;
  document.body.appendChild(eleLink);
  // 触发点击
  eleLink.click();
  // 然后移除
  document.body.removeChild(eleLink);
};

/**
 * 通过blob下载文件
 * @param name 下载文件的文件名
 * @param blob 文件对象
 */
export const downloadFileByBlob = (name: string, blob: Blob): void => {
  // 创建隐藏的可下载链接
  const eleLink = document.createElement('a');
  eleLink.download = name;
  eleLink.style.display = 'none';
  // 字符内容转变成blob地址
  eleLink.href = URL.createObjectURL(blob);
  // 触发点击
  document.body.appendChild(eleLink);
  eleLink.click();
  // 然后移除
  document.body.removeChild(eleLink);
  // 释放由 createObjectURL 创建的 object URL
  URL.revokeObjectURL(eleLink.href);
};

/**
 * 把省市区一维数组转换为省市区对象
 * @param provinces 省市区数组
 * @returns 省市区对象
 */
export const convertProvinces = (
  provinces: Array<string>
): { province: string; city: string; district: string } => {
  const [province, city, district] = provinces;
  return { province, city, district };
};

export const messageError = (apiError?: ApiError | Error): void => {
  // 处理程序运行出现的异常
  if (!apiError) {
    console.warn('未捕获到任何错误');
    return;
  }
  if (!(apiError as ApiError).level) {
    console.error((apiError as Error).message);
  }

  // 处理请求出现的异常（包括前端自定义异常、http异常、api返回的业务异常）
  const requestError: ApiError = apiError as ApiError;
  switch (requestError.level) {
    case ErrorLevelEnum.error:
      if (requestError.message) {
        Message.error(requestError.message);
      }
      break;
    case ErrorLevelEnum.warning:
      if (requestError.message) {
        Message.warning(requestError.message);
      }
      break;
    case ErrorLevelEnum.info:
      if (requestError.message) {
        Message.info(requestError.message);
      }
      break;
    default:
      break;
  }
};

/**
 * 获取用于下拉列表绑定的资源状态项
 * @returns 用于下拉列表绑定的资源状态项
 */
export const getResourceStatusOptions = (): Array<{ label: string; value: number }> => {
  return [
    {
      label: i18n.t('common.startUsing') as string,
      value: 1
    },
    {
      label: i18n.t('common.disabled') as string,
      value: 2
    }
  ];
};

/**
 * 时间格式化
 * @param value 要格式化的时间
 * @param formatStr 格式 默认为YYYY-MM-DD HH:mm:ss
 * @returns
 */
export const dateFormat = (value: Date | string, formatStr = 'YYYY-MM-DD HH:mm:ss'): string => {
  if (!value) {
    return '--';
  }
  return moment(value).format(formatStr);
};

/**
 * 计算时间天数差
 * @param beginTime 开始时间
 * @param endTime 结束时间
 * @returns 返回格式 例如：3
 */
export const getTimeDiffDay = (beginTime: Date | string, endTime: Date | string): number => {
  // 时间差的毫秒数
  const diffMs = new Date(endTime).getTime() - new Date(beginTime).getTime();
  // 计算出相差小时数
  // eslint-disable-next-line radix
  const diffDays = parseInt((diffMs / (24 * 60 * 60 * 1000)).toString());
  return diffDays;
};

/**
 * 计算时间差
 * @param beginTime 开始时间
 * @param endTime 结束时间
 * @returns 返回格式 例如：1时20分30秒
 */
export const getTimeDiff = (beginTime: Date | string, endTime: Date | string): string => {
  // 时间差的毫秒数
  const diffMs = new Date(endTime).getTime() - new Date(beginTime).getTime();

  // 计算出相差小时数
  // eslint-disable-next-line radix
  const diffHours = parseInt((diffMs / (60 * 60 * 1000)).toString());

  // 计算相差分钟数
  // eslint-disable-next-line radix
  const diffMinutes = parseInt(((diffMs % (1000 * 60 * 60)) / (1000 * 60)).toString());

  // 计算相差秒数
  const diffSeconds = (diffMs % (1000 * 60)) / 1000;

  return `
  ${diffHours}${translation('time.hours')}${diffMinutes}${translation(
    'time.minutes'
  )}${diffSeconds}${translation('time.seconds')}
  `;
};

export const handleDownload = (axiosRes: AxiosResponse<Blob>): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (axiosRes.data.type === 'application/json') {
      const fileReader = new FileReader();
      fileReader.readAsText(axiosRes.data, 'UTF-8');
      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        const errorJson: ApiResponse<null> = event.target ? JSON.parse(event.target.result as string) : {};
        const errorObj: ApiError = getErrorObj(errorJson.code);
        reject(errorObj);
      };
      return;
    }
    resolve(axiosRes.data);
  });
};

/**
 * 获取文件后缀名
 * @param path 路径或文件名
 */
export const getFileExtension = (path: string): string => {
  if (!path) {
    console.error('文件不符合规范');
    return 'unKnown';
  }
  const splitPath = path.split('.');
  if (splitPath.length === 0) {
    console.error('文件不符合规范');
    return 'unKnown';
  }
  return splitPath.pop()!;
};

/**
 * 获取文件名
 * @param path 路径或文件名
 */
export const getFileName = (path: string): string => {
  if (!path) {
    console.error('文件不符合规范');
    return 'unKnown';
  }
  const splitPath = path.split('.');
  if (splitPath.length === 0) {
    console.error('文件不符合规范');
    return 'unKnown';
  }
  return splitPath.shift()!;
};

/**
 * 判断对象内的字段值是否发生变化
 * @param obj1 对象1
 * @param obj2 对象2
 * @param fields 要对比的字段
 * @returns 变化则返回true
 */
export function objIsChange<T extends object, K extends object>(
  obj1: T,
  obj2: K,
  fields: Array<Extract<keyof T, keyof K>>
): boolean {
  return !fields.every(field => (obj1[field] as any) === (obj2[field] as any));
}

/**
 * 检查字符串是否为json字符串
 * @param str 要检查的字符串
 * @returns
 */
export const isJsonStr = (str: string): boolean => {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true;
    }
  } catch (e) {}
  return false;
};

/**
 * 四舍五入保留两位小数
 * @param val
 * @returns
 */
export const mathRound2 = (val: number): number => {
  return Math.round(val * 100) / 100;
};

/**
 * 四舍五入保留六位小数
 * @param val
 * @returns
 */
export const mathRound6 = (val: number): number => {
  return Math.round(val * 1000000) / 1000000;
};

/**
 * 判断基础数据属性是否为null或undefined
 * @param val
 * @returns
 */
export const isNullOrUndefinedForBaseType = (val: string | number | boolean): boolean => {
  return !val && val !== 0;
};

export const messageErrors = (errors?: Array<ApiError> | Error): void => {
  // 处理程序运行出现的异常
  if (!errors) {
    console.warn('未捕获到任何错误');
    return;
  }
  if (!Array.isArray(errors)) {
    console.error((errors as Error).message);
    return;
  }
  const messages = errors.reduce((prev, error) => {
    return `${prev}</br>${error.message}`;
  }, '');

  Notification.error({
    title: translation('operationRes.operationFailed'),
    duration: 0,
    dangerouslyUseHTMLString: true,
    message: `<span>${messages}</span><br />`
  });
};

/**
 * 防抖  时间内 只执行最后一次的函数 延迟0.3秒执行 再此期间再调用会重新计时
 * @param time
 * @returns
 */
export const debounce = function(time: number = 0.3) {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const oldFun = descriptor.value;
    let timeid: any = null;
    descriptor.value = function(this: unknown, ...args: any[]) {
      if (timeid) {
        clearTimeout(timeid);
      }
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      timeid = setTimeout(() => {
        oldFun.apply(this, args);
      }, time * 1000);
    } as Function;
    return descriptor;
  };
};
