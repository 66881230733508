/**
 * 生产工序
 */
export enum ProcessEnum {
  /**
   * 印前
   */
  prepress = 1,
  /**
   * 输出
   */
  printing,
  /**
   * 后道
   */
  backend,
  /**
   * 出货
   */
  shipment,
  /**
   * 无需制作
   */
  noProductionNeed = -1
}
