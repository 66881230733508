/**
 * 材料计量单位
 */
export enum MaterialUnitEnum {
  /**
   * 米
   */
  meter = 1,
  /**
   * 厘米
   */
  centimetre = 2,
  /**
   * 毫米
   */
  millimetre = 3
}
