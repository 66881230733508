/**
 * 工序状态
 */
export enum ProcessStatusEnum {
  /**
   * 待制作
   */
  waiting = 1,
  /**
   * 制作中
   */
  processing = 2,
  /**
   * 已完成
   */
  completed = 3
  // /**
  //  * 不制作
  //  */
  // closed = -1
}
