/**
 * 部门工序绑定
 */
export enum BindProcessEnum {
  /**
   * 印前
   */
  prepress = 1,
  /**
   * 输出
   */
  printing,
  /**
   * 后道
   */
  backend
}
