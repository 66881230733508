import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import ECB from 'crypto-js/mode-ecb';
import Pkcs7 from 'crypto-js/pad-pkcs7';

const secretKey = 'Pr1m@ry_0S_P@55w';

/**
 * Aes加密
 * @param word 要加密的内容
 * @param secretKey 秘钥
 * @returns 加密结果
 */
export function encryptAes(word: string): string {
  const key = Utf8.parse(secretKeyCovering(secretKey));
  const srcs = Utf8.parse(word);
  const encrypted = AES.encrypt(srcs, key, { mode: ECB, padding: Pkcs7 });
  return encrypted.toString();
}

/**
 * 解密
 * @param encrypted 加密后的结果
 * @param secretKey 秘钥
 * @returns 解密结果
 */
export function decryptAes(encrypted: string): string {
  const key = Utf8.parse(secretKeyCovering(secretKey));
  const decrypt = AES.decrypt(encrypted, key, { mode: ECB, padding: Pkcs7 });
  return Utf8.stringify(decrypt).toString();
}

/**
 * 自适应补充秘钥长度
 * @param secretKey 秘钥
 * @returns
 */
function secretKeyCovering(secretKey: string): string {
  const arr = [16, 24, 32];
  const filterArr = arr.filter(x => x >= secretKey.length);
  if (filterArr.length === 0) {
    console.error('秘钥长度不合法');
    return secretKey;
  }
  let res = secretKey;
  for (let index = 0; index < filterArr[0] - secretKey.length; index++) {
    res += '0';
  }
  return res;
}
