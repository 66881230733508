import { PermissionModule } from '../store/modules/permission';
import { DirectiveOptions } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

/**
 * 细粒度的操作权限指令
 */
export const permissionOperation: DirectiveOptions = {
  inserted(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    const operations: Array<string> = PermissionModule.permissionOperations;
    if (value && typeof value === 'string') {
      const hasPermission = operations.includes(value);
      if (!hasPermission) {
        el.style.display = 'none';
      }
    }
    //  else {
    //   throw new Error('need roles! Like v-permissionOperation = "auth:department:edit"');
    // }
  }
};
