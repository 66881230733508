/**
 * 发货单状态枚举
 */
export enum DeliveryStatusEnum {
  // /**
  //  * 新建
  //  */
  // new = 1,
  /**
   * 待接单
   */
  waitTakeOrder = 2,
  /**
   * 待发货
   */
  waitDelivery,
  /**
   * 部分发货
   */
  partDelivery,
  /**
   * 已发货
   */
  delivered,
  /**
   * 已完成
   */
  complete
}
