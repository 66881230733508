import { TagsViewModule } from '@/store/modules/tags-view';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'app-main'
})
export default class AppMain extends Vue {
  public get cachedViews(): Array<string | undefined> {
    return TagsViewModule.cachedViews;
  }

  public get key(): string {
    return this.$route.path;
  }
}
