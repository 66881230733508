/**
 * 物流单据状态
 */
export enum LogisticsOrderStatusEnum {
  /**
   * 待发货
   */
  new = 1,
  /**
   * 待揽收
   */
  receivedGoods = 2,

  /**
   * 在途中
   */
  inTransit = 3,
  /**
   * 异常
   */
  error = 4,
  /**
   * 已签收
   */
  received = 5,

  /**
   * 已关闭
   */
  closed = 6
}
