/**
 * 资源状态
 */
export const enum ResourceStatusEnum {
  /**
   * 启用
   */
  using = 1,
  /**
   * 禁用
   */
  disabled = 2
}
