import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import { constantRoutes, dynamicRoutes, mergeRouteConfigs } from '@/router';
import store from '@/store';
import { PermissionRoute } from '@/resource/model';

export interface IPermissionState {
  /**
   * 全部路由表
   */
  routes: RouteConfig[];
  /**
   * 当前登录用户角色可访问的路由表
   */
  permissionRoutes: RouteConfig[];
  /**
   * 当前登录用户可进行的操作
   */
  permissionOperations: Array<string>;
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = [];
  public permissionRoutes: RouteConfig[] = [];
  public permissionOperations: Array<string> = [];

  /**
   * 生成路由表
   * @param routes 当前登录角色可访问的路由
   */
  @Action
  public GenerateRoutes(permissionRoutes: PermissionRoute[]): void {
    const mergedRoutes =
      process.env.VUE_APP_IS_FILTER_ROUTE === 'true'
        ? mergeRouteConfigs(
            dynamicRoutes,
            permissionRoutes.filter(x => x.url !== '/dashboard')
          )
        : dynamicRoutes;
    this.setRoutes(mergedRoutes);
  }

  /**
   * 设置按钮权限
   * @param routes 权限路由
   */
  @Action
  public SetPermissionOperations(operations: Array<string>): void {
    this.setOperations(operations);
  }

  /**
   * 清除权限
   */
  @Action
  public ClearPermission(): void {
    this.setOperations([]);
    this.setRoutes([]);
  }

  /**
   * 设置路由
   * @param dynamicRoutes 动态路由
   */
  @Mutation
  private setRoutes(dynamicRoutes: RouteConfig[]): void {
    // 最后添加404，不然所有页面的访问都会被拦截
    dynamicRoutes.push({
      path: '*',
      redirect: '/404',
      meta: { hidden: true }
    });
    this.routes = constantRoutes.concat(dynamicRoutes);
    this.permissionRoutes = dynamicRoutes;
  }

  /**
   * 设置操作权限
   * @param operations 操作权限
   */
  @Mutation
  private setOperations(operations: Array<string>): void {
    this.permissionOperations = operations;
  }
}

export const PermissionModule = getModule(Permission);
