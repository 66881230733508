import { ResourceUrlEnum } from '@/resource/enum';
import { ElectronicExpressWaybillTemplate, PrinterSave } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class ElectronicExpressWaybillService extends BaseService<ElectronicExpressWaybillTemplate> {
  public getById(id: number): Promise<ElectronicExpressWaybillTemplate> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ElectronicExpressWaybillTemplate>(ResourceUrlEnum.electronicExpressWaybill, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: PrinterSave): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<PrinterSave>(ResourceUrlEnum.electronicExpressWaybill, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: PrinterSave): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<PrinterSave>(ResourceUrlEnum.electronicExpressWaybill, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.electronicExpressWaybill, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: { keywords: string },
    paging?: Paging
  ): Promise<ApiResponse<Array<ElectronicExpressWaybillTemplate>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ElectronicExpressWaybillTemplate>>(
          `${ResourceUrlEnum.electronicExpressWaybill}/listPage`,
          cloneQuery
        )
        .then((res: ApiResponse<Array<ElectronicExpressWaybillTemplate>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有可以使用的电子面单模板
   * @returns
   */
  public getAllUsing(): Promise<Array<ElectronicExpressWaybillTemplate>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ElectronicExpressWaybillTemplate>>(
          `${ResourceUrlEnum.electronicExpressWaybill}/listEnable`
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public checkNameRepeat(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.electronicExpressWaybill}/checkNameRepeat`, { params: { id, name } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有快递公司
   * @returns
   */
  public getExpressCompanys(): Promise<Array<{ id: number; name: string; code: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ id: number; name: string; code: string }>>(
          `${ResourceUrlEnum.electronicExpressWaybill}/listExpressCompany`
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取快递公司所有快递类型
   * @param expressCompanyId 快递公司主键
   * @returns
   */
  public getExpressTypeByCompany(expressCompanyId: number): Promise<Array<{ id: number; name: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ id: number; name: string }>>(
          `${ResourceUrlEnum.electronicExpressWaybill}/listExpTypeByExpressCompanyId`,
          { params: { expressCompanyId } }
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取快递公司的模板样式
   * @param expressCompanyId 快递公司id
   * @returns
   */
  public getExpressTemplateByCompany(expressCompanyId: number): Promise<Array<{ id: number; name: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ id: number; name: string }>>(
          `${ResourceUrlEnum.electronicExpressWaybill}/listHundredTemplate`,
          { params: { expressCompanyId } }
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const electronicExpressWaybillService = new ElectronicExpressWaybillService();
export default electronicExpressWaybillService;
