import { Subject } from 'rxjs';

class LocalStorageService {
  /**
   * 外部如果对本地缓存某个key的set和remove感兴趣，可以使用该subject，当value发生变动时，会通知订阅方
   */
  public changes$ = new Subject<{
    type: 'set' | 'remove';
    key: string;
    value?: string;
  }>();

  private localStorage: Storage;

  public constructor() {
    this.localStorage = window.localStorage;
  }

  public get<T>(key: string): T | null {
    if (!this.isLocalStorageSupported) {
      return null;
    }
    if (!this.localStorage.getItem(key)) {
      return null;
    }
    return JSON.parse(this.localStorage.getItem(key)!);
  }

  public set(key: string, value: any): boolean {
    if (!this.isLocalStorageSupported) {
      return false;
    }
    this.localStorage.setItem(key, JSON.stringify(value));
    this.changes$.next({
      type: 'set',
      key,
      value
    });
    return true;
  }

  public remove(key: string): boolean {
    if (!this.isLocalStorageSupported) {
      return false;
    }
    this.localStorage.removeItem(key);
    this.changes$.next({
      type: 'remove',
      key
    });
    return true;
  }

  private get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }

  private isJson(str: string): boolean {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}
export default new LocalStorageService();
