/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const systemSettingRoutes: RouteConfig = {
  path: '/system-setting',
  name: 'SystemSetting',
  component: Layout,
  redirect: 'role',
  children: [
    {
      path: '/role',
      name: 'Role',
      component: async () => import(/* webpackChunkName: "role" */ '@/views/system-setting/role/role.vue'),
      meta: {
        title: 'route.role',
        id: 824
      }
    },
    {
      path: '/device',
      name: 'Device',
      component: async () =>
        import(/* webpackChunkName: "device" */ '@/views/system-setting/device/device.vue'),
      meta: {
        title: 'route.device',
        id: 825
      }
    },
    {
      path: '/message-config',
      name: 'MessageConfig',
      component: async () =>
        import(
          /* webpackChunkName: "message-config" */ '@/views/system-setting/message-config/message-config.vue'
        ),
      meta: {
        title: 'route.messageConfig',
        id: 826
      }
    },
    {
      path: '/sender',
      name: 'Sender',
      component: async () =>
        import(/* webpackChunkName: "sender" */ '@/views/system-setting/sender/sender.vue'),
      meta: {
        title: 'route.sender',
        id: 1197
      }
    },
    {
      path: '/printer',
      name: 'Printer',
      component: async () =>
        import(/* webpackChunkName: "printer" */ '@/views/system-setting/printer/printer.vue'),
      meta: {
        title: 'route.printer',
        id: 1223
      }
    },
    {
      path: '/electronic-express-waybill',
      name: 'ElectronicExpressWaybill',
      component: async () =>
        import(
          /* webpackChunkName: "electronic-express-waybill" */ '@/views/system-setting/electronic-express-waybill/electronic-express-waybill.vue'
        ),
      meta: {
        title: 'route.electronicExpressWaybill',
        id: 1451
      }
    },
    {
      path: '/system-config',
      name: 'SystemConfig',
      component: async () =>
        import(
          /* webpackChunkName: "system-config" */ '@/views/system-setting/system-config/system-config.vue'
        ),
      meta: {
        title: 'route.systemConfig',
        id: 1630
      }
    }
  ],
  meta: {
    title: 'route.systemSetting',
    icon: '#system-setting',
    id: 823
  }
};
