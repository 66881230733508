import { Component, Prop, Vue } from 'vue-property-decorator';
import { ElButton } from 'element-ui/types/button';
import EditQueryPlan from './edit-query-plan/edit-query-plan.vue';
import OperationQueryPlan from './operation-query-plan/operation-query-plan.vue';
import { queryPlanService } from '@/api';
import { messageError } from '@/utils';
export interface QueryPlanOperationOption extends Partial<ElButton> {
  id: number;
  /**
   * 查询方案值
   */
  json?: String;
  /**
   * 操作类型，用于标识按钮的唯一性
   */
  operationType?:
    | 'add'
    | 'delete'
    | 'using'
    | 'disabled'
    | 'import'
    | 'export'
    | 'repair'
    | 'confirm'
    | string;
  /**
   * 按钮显示的文本
   * 请保证按钮文本和图标至少有一项
   */
  label?: string;
  /**
   * 按钮的图标
   */
  icon?: string;
  /**
   * 按钮显示的位置
   */
  slot: 'start' | 'end';
  /**
   * 权限标识
   */
  permissionCode?: string;
  handleClick?: () => void;
  /**
   * 是否动态隐藏该操作按钮,不传则默认显示按钮
   * true则隐藏
   */
  dynamicHidden?: () => boolean;
}
@Component({
  name: 'os-query-plan',
  components: {
    EditQueryPlan,
    OperationQueryPlan
  }
})
export default class OsQueryPlan extends Vue {
  @Prop({ type: Array, required: true }) public queryEndPlanOptions!: Array<QueryPlanOperationOption>;
  /**
   * 页面标识
   */
  @Prop({ type: Number, required: true }) public code!: string;
  @Prop({ type: String, required: true }) public json!: string;

  public startPosition: Array<QueryPlanOperationOption> = [];

  public get endPosition(): Array<QueryPlanOperationOption> {
    return this.queryEndPlanOptions.filter(x => x.slot === 'end');
  }

  public dialogVisible = false;
  public dialogEditVisible = false;

  /**
   * 选中方案
   */
  public id: number = 0;
  public queryPlanName: string = '';

  public created(): void {}

  public mounted(): void {
    this.getQueryPlanList();
  }

  /**
   * 打开弹窗回调
   */
  public dialogOpened(): void {
    console.log('打开弹窗');
    this.dialogVisible = true;
  }
  /**
   * 关闭弹窗回调
   */
  public dialogClosed(): void {
    console.log('弹窗关闭');
  }
  /**
   * 保存或更新成功的回调
   */
  public saveSuccess(): void {
    this.getQueryPlanList();
  }

  /**
   * 打开编辑弹窗
   */
  public editDialogOpened(): void {
    console.log('打开编辑弹窗');
    this.dialogEditVisible = true;
  }

  /**
   * 从首页跳转执行方案
   */
  public selectQueryPlan(queryPlanName: string): void {
    const time = setTimeout(() => {
      this.startPosition.forEach(item => {
        if (item.label === queryPlanName) {
          this.onclick(item);
        }
        clearTimeout(time);
      });
    }, 1000);
  }

  public onclick(item: QueryPlanOperationOption): void {
    this.id = item.id;
    this.queryPlanName = item.label!;
    this.$emit('query-list', item.json);
  }

  public async getQueryPlanList(): Promise<void> {
    queryPlanService
      .getList({ code: this.code })
      .then(result => {
        this.startPosition = result.data.map(item => {
          return {
            id: item.id,
            slot: 'start',
            label: item.name,
            json: item.json
          };
        });
        this.startPosition.unshift({
          id: 0,
          slot: 'start',
          label: '默认方案',
          operationType: 'add',
          json: '{}'
        });
      })
      .catch(err => {
        messageError(err);
      });
  }
}
