import { ProductionProductStatusEnum } from './../../resource/enum/production-product-status';
import { ProcessStatusEnum } from './../../resource/enum/process-status';
import {
  LayoutStatusEnum,
  PlatformPrepressStatusEnum,
  ProcessEnum,
  ProjectItemTypeEnum,
  ResourceUrlEnum
} from '@/resource/enum';
import { Paging, Query } from '../base';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ProductionOrderProductResource } from '@/resource/model';
import { translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class ProductionOrderProductService {
  public getById(id: number): Promise<ProductionOrderProductResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionOrderProductResource>(`${ResourceUrlEnum.productionOrderProduct}/findById`, {
          params: { id }
        })
        .then((res: ApiResponse<ProductionOrderProductResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Query<ProductionOrderProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderProductResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderProductResource>>(
          `${ResourceUrlEnum.productionOrderProduct}/listPage`,
          cloneQuery
        )
        .then((res: ApiResponse<Array<ProductionOrderProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据工序状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getProcessStatusI18Key(status: ProcessStatusEnum): string {
    return 'processStatus.' + ProcessStatusEnum[status];
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return 'orderProductStatus.' + ProductionProductStatusEnum[status];
  }

  /**
   * 根据工序获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getProcessI18Key(status: ProcessEnum): string {
    return 'orderProduct.' + ProcessEnum[status];
  }

  /**
   * 根据工序状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: ProductionProductStatusEnum): string {
    let className = '';
    switch (status) {
      case ProductionProductStatusEnum.waitPlan:
        className = 'info-dot';
        break;
      case ProductionProductStatusEnum.new:
        className = 'warning-dot';
        break;
      case ProductionProductStatusEnum.processing:
        className = 'primary-dot';
        break;
      case ProductionProductStatusEnum.completed:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 获取全部单据可选的单据状态
   * @returns
   */
  public getStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in ProductionProductStatusEnum) {
      if (!isNaN(Number(key)) && Number(key) !== ProductionProductStatusEnum.waitPlan) {
        list.push({
          label: translation(`orderProductStatus.${ProductionProductStatusEnum[key]}`),
          value: key
        });
      }
    }

    return list;
  }

  /**
   * 获取可选的平台印前状态
   * @returns
   */
  public getPlatformStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PlatformPrepressStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`platformPrepressStatus.${key}`),
          value: PlatformPrepressStatusEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取印前输出后道可选的工序状态
   * @returns
   */
  public getProcessStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in ProcessStatusEnum) {
      if (!isNaN(Number(key)) && Number(key) !== ProcessStatusEnum.completed) {
        list.push({
          label: translation(`processStatus.${ProcessStatusEnum[key]}`),
          value: key
        });
      }
    }

    return list;
  }

  /**
   * 获取印前输出后道工序制作状态
   * @returns
   */
  public getProcessStatus(): NormalSelectOptions {
    const list: NormalSelectOptions = [];
    for (const key in ProcessStatusEnum) {
      if (isNaN(Number(key))) {
        list.push({
          label: translation(`processStatus.${key}`),
          value: ProcessStatusEnum[key]
        });
      }
    }

    return list;
  }
  /**
   * 获取layout单打印状态
   * @returns
   */
  public getLayoutStatus(): NormalSelectOptions {
    const list: NormalSelectOptions = [];
    for (const key in LayoutStatusEnum) {
      if (isNaN(Number(key))) {
        list.push({
          label: translation(`layoutStatus.${key}`),
          value: LayoutStatusEnum[key]
        });
      }
    }

    return list;
  }

  /**
   * 获取项次类型
   * @returns
   */
  public getItemType(): NormalSelectOptions {
    const list: NormalSelectOptions = [];
    for (const key in ProjectItemTypeEnum) {
      if (isNaN(Number(key))) {
        list.push({
          label: translation(`projectItemType.${key}`),
          value: ProjectItemTypeEnum[key]
        });
      }
    }

    return list;
  }
}
const productionOrderProductService = new ProductionOrderProductService();
export default productionOrderProductService;
