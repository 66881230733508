/**
 * 平台印前项次状态
 */
export enum PlatformPrepressStatusEnum {
  /**
   * 不制作
   */
  notMade = -1,
  /**
   * 待制作
   */
  waitForProduction = 1,
  /**
   * 制作中
   */
  inProduction,
  /**
   * 已完成
   */
  completed
}
