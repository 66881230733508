import { render, staticRenderFns } from "./verify.vue?vue&type=template&id=30bcf6a6&"
import script from "./verify.ts?vue&type=script&lang=ts&"
export * from "./verify.ts?vue&type=script&lang=ts&"
import style0 from "./verify.scoped.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports