import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule, DeviceType } from '@/store/modules/app';

const WIDTH = 992; // refer to Bootstrap's responsive design

@Component({
  name: 'ResizeMixin'
})
export default class extends Vue {
  public get device(): DeviceType {
    return AppModule.device;
  }

  public get sidebar(): { opened: boolean; withoutAnimation: boolean } {
    return AppModule.sidebar;
  }

  public beforeMount(): void {
    window.addEventListener('resize', this.resizeHandler);
  }

  public mounted(): void {
    const isMobile = this.isMobile();
    if (isMobile) {
      AppModule.ToggleDevice(DeviceType.Mobile);
      AppModule.CloseSideBar(true);
    }
  }

  public beforeDestroy(): void {
    window.removeEventListener('resize', this.resizeHandler);
  }

  @Watch('$route')
  private onRouteChange(): void {
    if (this.device === DeviceType.Mobile && this.sidebar.opened) {
      AppModule.CloseSideBar(false);
    }
  }

  private isMobile(): boolean {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < WIDTH;
  }

  private resizeHandler(): void {
    if (!document.hidden) {
      const isMobile = this.isMobile();
      AppModule.ToggleDevice(isMobile ? DeviceType.Mobile : DeviceType.Desktop);
      if (isMobile) {
        AppModule.CloseSideBar(true);
      }
    }
  }
}
