/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

/**
 * 建议反馈理路由配置
 */
export const feedbackSuggestionRoutes: RouteConfig = {
  path: '/feedback-suggestion',
  component: Layout,
  redirect: 'feedback-setting',
  name: 'FeedbackSuggestion',
  children: [
    // {
    //   path: '/feedback-suggestions-list',
    //   name: 'FeedbackSuggestionList',
    //   component: async () =>
    //     import(
    //       /* webpackChunkName: "feedback-suggestions-list" */ '@/views/feedback-suggestion/feedback-suggestions-list/feedback-suggestions-list.vue'
    //     ),
    //   meta: {
    //     title: 'route.feedbackSuggestionList',
    //     id: 1927,
    //     parentId: 1924
    //   }
    // },
    {
      path: '/add-feedback-suggestion',
      name: 'AddFeedbackSuggestion',
      component: async () =>
        import(
          /* webpackChunkName: "add-feedback-suggestion" */ '@/views/feedback-suggestion/add-feedback-suggestion/add-feedback-suggestion.vue'
        ),
      meta: {
        title: 'route.addFeedbackSuggestion',
        id: 1934,
        icon: '#product',
        parentId: 1933
      }
    }
  ],
  meta: {
    title: 'route.feedbackSuggestion',
    icon: '#product',
    id: 1933
  }
};
