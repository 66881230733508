import VerifySlide from './verify-slide/verify-slide.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
export interface MyNavigator {
  browserLanguage: string;
}
@Component({
  name: 'verify',
  components: {
    VerifySlide
  }
})
export default class Verify extends Vue {
  @Prop({
    default: () => {
      return navigator.language ? navigator.language : 'zh-cn';
    }
  })
  locale!: string;
  @Prop({ type: String, required: true, default: 'blockPuzzle' }) public captchaType!: string;
  @Prop(Number) public figure!: number;
  @Prop(Number) public arith!: number;
  @Prop(Number) public vSpace!: number;
  @Prop({ default: 'pop' }) public mode!: string;
  @Prop(String) public explain!: string;
  @Watch('explain', { immediate: true })
  handlerExplain(explain: string) {
    this.explain = explain;
  }
  @Prop({
    default: () => {
      return {
        width: 310,
        height: 155
      };
    }
  })
  public imgSize!: { width: string; height: string };
  @Prop(Object) public blockSize!: { width: string; height: string };
  @Prop(Object) public barSize!: { width: string; height: string };

  @Watch('captchaType', { immediate: true })
  handlerCaptchaType(captchaType: string) {
    switch (captchaType.toString()) {
      case 'blockPuzzle':
        this.verifyType = '2';
        this.componentType = 'VerifySlide';
        break;
    }
  }
  // showBox:true,
  public clickShow: boolean = false;
  // 内部类型
  public verifyType: string = '';
  // 所用组件类型
  public componentType: string = '';
  // 默认图片
  public defaultImg = require('@/assets/images/captcha-default.jpg');
  public created(): void {
    this.uuid();
  }

  // 生成 uuid
  uuid() {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    // bits 12-15 of the time_hi_and_version field to 0010
    s[14] = '4';
    // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[19] = hexDigits.substr(((s[19] as any) & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';

    var slider = 'slider' + '-' + s.join('');
    var point = 'point' + '-' + s.join('');
    if (!localStorage.getItem('slider')) {
      localStorage.setItem('slider', slider);
    }
    if (!localStorage.getItem('point')) {
      localStorage.setItem('point', point);
    }
  }

  /**
   * i18n
   * @description 兼容vue-i18n 调用$t来转换ok
   * @param {String} text-被转换的目标
   * @return {String} i18n的结果
   * */
  i18n(text: string) {
    return this.$t(text);
  }

  /**
   * refresh
   * @description 刷新
   * */
  refresh() {
    if (this.instance().refresh) {
      this.instance().refresh();
    }
  }

  closeBox() {
    this.clickShow = false;
    this.refresh();
  }

  show() {
    if (this.mode === 'pop') {
      this.clickShow = true;
    }
  }

  public instance(): any {
    return this.$refs.instance;
  }

  public showBox(): boolean {
    if (this.mode === 'pop') {
      return this.clickShow;
    } else {
      return true;
    }
  }
}
