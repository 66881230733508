/**
 * 计价单位
 */
export enum ChargeUnitEnum {
  /**
   * 平方米
   */
  squareMeter = 1,
  /**
   * 张
   */
  piece,
  /**
   * 套
   */
  cover,
  /**
   * 个
   */
  individual
}
