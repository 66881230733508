/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const productManagementRoutes: RouteConfig = {
  path: '/product-management',
  name: 'ProductManagement',
  component: Layout,
  redirect: 'product',
  children: [
    {
      path: '/product',
      name: 'Product',
      component: async () =>
        import(/* webpackChunkName: "product" */ '@/views/product-management/product/product.vue'),
      meta: {
        title: 'route.productList',
        id: 832
      }
    }
  ],
  meta: {
    title: 'route.product',
    icon: '#product',
    id: 831
  }
};
