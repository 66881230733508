import { MessageStatusEnum } from '@/resource/enum';
import { MessageResource } from '@/resource/model';
import { MessageCenterModule } from '@/store/modules/message';
import { Component, Vue } from 'vue-property-decorator';
import { MessageTab } from '../message-center';
@Component({
  components: {}
})
export default class MiniMessageCenter extends Vue {
  public activeTab: MessageTab = 'notice';

  public get notices(): Array<MessageResource> {
    return MessageCenterModule.notices;
  }

  public get todos(): Array<MessageResource> {
    return MessageCenterModule.todos;
  }

  public async setReaded(message: MessageResource): Promise<void> {
    this.$notify({
      title: message.title,
      message: message.content,
      dangerouslyUseHTMLString: true
    });
    if (message.status === MessageStatusEnum.unRead) {
      MessageCenterModule.SetReaded(message);
    }
  }

  /**
   * 是否已读
   * @param status
   * @returns
   */
  public isReaded(status: MessageStatusEnum): boolean {
    return status === MessageStatusEnum.readed;
  }
}
