export enum ErrorLevelEnum {
  /**
   * 错误
   */
  error = 1,
  /**
   * 警告
   */
  warning,
  /**
   * 提示信息
   */
  info
}
