import { render, staticRenderFns } from "./mini-login.vue?vue&type=template&id=6ec4a3d5&scoped=true&"
import script from "./mini-login.ts?vue&type=script&lang=ts&"
export * from "./mini-login.ts?vue&type=script&lang=ts&"
import style0 from "./mini-login.scoped.scss?vue&type=style&index=0&id=6ec4a3d5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec4a3d5",
  null
  
)

export default component.exports