/**
 * 发票类型
 */
export enum InvoiceTypeEnum {
  /**
   * 增值税专用发票
   */
  vatSpecialInvoice = 1,
  /**
   * 增值税普通发票
   */
  valueAddedTaxInvoice,
  /**
   * 普通电子票
   */
  electronicGeneralTicket,
  /**
   * 形式发票
   */
  formalInvoice
}
