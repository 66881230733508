import Vue from 'vue';
import Vuex from 'vuex';
import { IAddressState } from './modules/address';
import { IAppState } from './modules/app';
import { IMessageCenterState } from './modules/message';
import { IPermissionState } from './modules/permission';
import { ITagsViewState } from './modules/tags-view';
import { IUserState } from './modules/user';

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
  user: IUserState;
  permission: IPermissionState;
  messageCenter: IMessageCenterState;
  address: IAddressState;
  tagsView: ITagsViewState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});
