import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export class DialogMixin extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false, type: Boolean }) public syncedVisible!: boolean;

  /**
   * 正常的表单宽度(单列)
   */
  public readonly normalFormWidth = '420px';

  /**
   * 分两列展示
   */
  public readonly towColumnFormWidth = '720px';

  /**
   * 分三列展示
   */
  public readonly threeColumnFormWidth = '910px';

  public title = '';

  public submitLoading = false;

  public openDialog(): void {
    this.syncedVisible = true;
  }

  public closeDialog(): void {
    this.syncedVisible = false;
  }

  public setLoading(value: boolean): void {
    this.submitLoading = value;
  }
}
