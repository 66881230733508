import { DeliveryModeEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  BatchEditCostParams,
  BatchEditLogisticsinfo,
  ElectronicBillDeliverySave,
  ElectronicBillPrintInfo,
  ImportRes,
  LogisticsDetail,
  LogisticsList,
  LogisticsListQuery,
  LogisticsProductList,
  SaveLogistics
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, messageError } from '@/utils';
import { AxiosResponse } from 'axios';

class LogisticsService {
  public getById(id: number): Promise<LogisticsDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<LogisticsDetail>(ResourceUrlEnum.logistics, { params: { id } })
        .then((res: ApiResponse<LogisticsDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SaveLogistics): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.deliveryNotice}/createLogistics`, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.logistics}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.logistics, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量发货
   * @param idList
   * @returns
   */
  public batchDelivery(idList: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchDelivery`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量收货
   * @param idList
   * @returns
   */
  public batchReceiving(idList: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchReceipt`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量打印电子面单（只针对非第三方物流的单据）
   * @param idList
   * @returns
   */
  public batchPrint(idList: Array<number>): Promise<Array<ElectronicBillPrintInfo>> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<Array<ElectronicBillPrintInfo>>(`${ResourceUrlEnum.logistics}/batchPrint`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 电子面单批量下单
   * @param idList 物流单主键数组
   * @param senderTemplateId 发件人模板
   * @param electronTemplateId 电子面单模板
   * @returns
   */
  public batchTakeElectronicBill(params: ElectronicBillDeliverySave): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/electronicsOrder`, params)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            if (res.code === 10001) {
              errorObj.message = res.message;
            }
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 订阅物流轨迹
   * @param idList
   * @returns
   */
  public subscribeTrack(idList: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/subscribe`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量改价
   * @param idList
   * @returns
   */
  public batchEditCost(requestList: Array<BatchEditCostParams>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchEditPrice`, requestList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量关闭
   * @param idList
   * @returns
   */
  public batchClose(requestObj: { idList: Array<number>; closeReason: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchClose`, requestObj)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改物流收发货信息
   * @param logisticsInfo
   * @returns
   */
  public batchEditLogisticsInfo(logisticsInfo: BatchEditLogisticsinfo): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchEditReceivingInfo`, logisticsInfo)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出物流单
   * @param id 物流单
   * @returns
   */
  public export(query: Partial<LogisticsListQuery>, idList: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const cloneQuery = {};
      Object.assign(cloneQuery, query);
      if (idList.length > 0) {
        Object.assign(cloneQuery, { idList: idList });
      }
      axiosRequest.instance
        .post<Blob>(`${ResourceUrlEnum.logistics}/export`, cloneQuery, {
          responseType: 'blob'
        })
        .then(res => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有关联了项目的客户列表
   */
  public getProjectCustomer(): Promise<Array<{ customerId: number; customerName: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ customerId: number; customerName: string }>>(`${ResourceUrlEnum.logistics}/getCustomer`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public downloadTemplate(ids: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.logistics}/downloadTemplate`, ids, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取出货方式
   * @returns
   */
  public getDeliveryMode(): Array<{ label: string; value: string }> {
    const deliveryModes: Array<{ label: string; value: string }> = [];
    for (const key in DeliveryModeEnum) {
      if (isNaN(Number(key))) {
        deliveryModes.push({
          label: `deliveryMode.${key}`,
          value: DeliveryModeEnum[key]
        });
      }
    }
    return deliveryModes;
  }
  /**
   * 导入发货
   * @param filePath
   * @returns
   */
  public importDelivery(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.logistics}/importDelivery`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   *  批量取消发货
   * @param ids 物流单id数组
   * @returns
   */
  public batchCancelDelivery(ids: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.logistics}/batchCancel`, ids)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入运费
   * @param filePath
   * @returns
   */
  public importFare(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.logistics}/importFreight`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取物流单下的项次信息
   * @returns
   */
  public getLogisticsProducts(id: number, paging: Paging): Promise<ApiResponse<Array<LogisticsProductList>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsProductList>>(`${ResourceUrlEnum.logistics}/listLogisticsItemPage`, {
          id,
          ...paging
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 刷新物流信息
   * @returns
   */
  public refreshTrack(id: number): Promise<LogisticsDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<LogisticsDetail>(`${ResourceUrlEnum.logistics}/refreshTrack`, { params: { id } })
        .then((res: ApiResponse<LogisticsDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const logisticsService = new LogisticsService();
export default logisticsService;
