/**
 * 责任机构类型
 */
export enum DutyAgencyTypeEnum {
  /**
   * 平台自营
   */
  platform = 1,
  /**
   * 生产供应商
   */
  productionSupplier,
  /**
   * 材料供应商
   */
  materialSupplier,
  /**
   * 物流供应商
   */
  logisticsSupplier,
  /**
   * 安装供应商
   */
  installSupplier
}
