import path from 'path';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { isExternal } from '@/utils/validate';
import SideBarItemLink from '../side-bar-item-link/side-bar-item-link.vue';
@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'side-bar-item',
  components: {
    SideBarItemLink
  }
})
export default class SideBarItem extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: '' }) private basePath!: string;

  public get showingChildNumber(): number {
    if (this.item.children) {
      const showingChildren = this.item.children.filter(item => {
        if (item.meta?.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  public get theOnlyOneChild(): RouteConfig | null {
    if (this.showingChildNumber >= 1 && this.item.name !== 'dashboard') {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    const route = { ...this.item, path: '' };
    delete route.children;
    return route;
  }

  private resolvePath(routePath: string): string {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
