/**
 * 工艺类型
 */
export enum CraftTypeEnum {
  /**
   * 输出工艺
   */
  output = 1,
  /**
   * 后道工艺
   */
  backend
}
