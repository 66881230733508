import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { getLanguage } from '@/utils/cookies';

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
// User defined lang
import enLocale from './en';
import zhLocale from './zh';

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
};

export const getLocale = (): string => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage;
    return cookieLanguage;
  }

  const language: string = navigator.language.toLowerCase();
  const locales: Array<string> = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale;
      return locale;
    }
  }

  // Default language is english
  return 'en';
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
  silentFallbackWarn: true
});

export default i18n;
