/**
 * 出货方式
 */
export enum ShippingTypeEnum {
    /**
     * 快递
     */
    express = 1,
    /**
     * 自提
     */
    selfPick = 2,
    /**
     * 专车
     */
    carDelivery = 3
}