import { DeliveryStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  DeliveryNoticeDetail,
  DeliveryNoticeList,
  DeliveryNoticeListQuery,
  ImportRes
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, translation } from '@/utils';
import { AxiosResponse } from 'axios';
import { NormalSelectOptions } from '@/resource/model/common';

class DeliveryNoticeService {
  public getById(id: number): Promise<DeliveryNoticeDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<DeliveryNoticeDetail>(ResourceUrlEnum.deliveryNotice, { params: { id } })
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 确认发货通知单
   * @param idList
   * @returns
   */
  public deliveryConfirm(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/confirm`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 完成发货通知单
   * @param idList
   * @returns
   */
  public deliveryComplete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/complete`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反完成发货通知单
   * @param idList
   * @returns
   */
  public batchCancelFinish(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/cancelFinish`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<DeliveryNoticeListQuery>,
    paging: Paging,
    sortOptions: SortOptions<DeliveryNoticeList>
  ): Promise<ApiResponse<Array<DeliveryNoticeList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeliveryNoticeDetail>>(`${ResourceUrlEnum.deliveryNotice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<DeliveryNoticeDetail>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出海报类发货明细
   * @param id 发货通知单主键
   * @returns
   */
  public exportPoster(ids: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.deliveryNotice}/export`, ids, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出pop类发货明细
   * @param id 发货通知单id
   * @returns
   */
  public exportPop(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.deliveryNotice}/exportPop`, {
          params: { id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入物流单
   * @param id 发货通知单id
   * @param filePath minio上的文件路径
   * @returns
   */
  public importLogistics(id: number, filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.deliveryNotice}/importLogisticsOrder`, { id, filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取发货通知单状态
   * @returns
   */
  public getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in DeliveryStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`deliveryNoticeStatus.${key}`),
          value: DeliveryStatusEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 取消接单
   * @param id 发货通知单id
   * @returns
   */
  public cancelTakeOrder(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/cancel`, null, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const deliveryNoticeService = new DeliveryNoticeService();
export default deliveryNoticeService;
