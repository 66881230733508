import { SvgIcon } from '@/components';
import Vue from 'vue';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('svg-icon', SvgIcon);

const req = require.context('./svg', false, /\.svg$/);
// eslint-disable-next-line no-undef
const requireAll = (requireContext: __WebpackModuleApi.RequireContext): Array<any> =>
  requireContext.keys().map(requireContext);
requireAll(req);
