/* eslint-disable no-undef */
type Key = string | number | object;

export interface ConnectOptions {
  /**
   * websocket实例的唯一标识
   */
  key: Key;
  /**
   * 连接地址
   */
  url: string;
  /**
   * 等待回应的超时时间 单位为毫秒
   * @default 5 * 1000
   */
  waitPongTimeOut?: number;
  /**
   * 发送ping的时间间隔 单位为毫秒
   * @default 10 * 1000
   */
  pingTimeInterval?: number;

  /**
   * websocket建立连接的超时时间 单位为毫秒
   * @default 10 * 1000
   */
  openTimeOut?: number;
  readonly onMessage?: (event: MessageEvent) => void;
  readonly onError?: (event: Event) => void;
  readonly onOpen?: (event: Event) => void;
  readonly onClose?: (event: CloseEvent) => void;
}
export interface OsWebsocket extends WebSocket {
  /**
   * 心跳机制等待回应的超时时间 单位为毫秒
   * @default 5 * 1000
   */
  waitPongTimeOut: number;
  /**
   * 心跳机制发送ping的时间间隔 单位为毫秒
   * @default 10 * 1000
   */
  pingTimeInterval: number;
  /**
   * websocket建立连接的超时时间 单位为毫秒
   * @default 10 * 1000
   */
  openTimeOut: number;
  /**
   * 定时器key => 心跳机制-检测到webscoekt断开连接时执行
   */
  heartbeatTaskId?: NodeJS.Timeout;
  /**
   * 定时器key => 建立webcoket连接超时时执行
   */
  openTaskId: NodeJS.Timeout;

  pingTaskId: NodeJS.Timeout;
}

class MyWebSocket {
  private webSocketMap: Map<string | number | object, OsWebsocket> = new Map();

  /**
   * 默认的等待回应的超时时间
   */
  private readonly waitPongTimeOut = 5 * 1000;

  /**
   * 默认的发送ping的时间间隔
   */
  private readonly pingTimeInterval = 10 * 1000;
  /**
   * 默认的websocket建立连接的超时时间 单位为毫秒
   */
  private readonly openTimeOut = 10 * 1000;

  /**
   * 建立用于某个业务的webSocket连接
   */
  public connect(options: ConnectOptions): OsWebsocket {
    const ws: OsWebsocket = new WebSocket(options.url) as OsWebsocket;
    ws.waitPongTimeOut = options.waitPongTimeOut ?? this.waitPongTimeOut;
    ws.pingTimeInterval = options.pingTimeInterval ?? this.pingTimeInterval;
    ws.openTimeOut = options.openTimeOut ?? this.openTimeOut;

    // 检查连接是否超时
    ws.openTaskId = this.handleConnectTimeOut(options, ws);

    ws.onopen = (event): void => {
      // 连接成功，清除连接超时定时器
      clearTimeout(ws.openTaskId!);

      console.log('Connection open ...');
      if (options.onOpen) {
        options.onOpen(event);
      }

      // 发送当前登录用户的token，用于建立连接
      ws.send(localStorage.getItem('token')!);

      // 保存当前webSocket实例
      this.webSocketMap.set(options.key, ws);

      // 开启心跳检测机制
      ws.pingTaskId = this.ping(options);
    };

    ws.onmessage = (event): void => {
      // 收到服务端回复的心跳约定
      if (event.data === '0xA' && ws.heartbeatTaskId) {
        clearTimeout(ws.heartbeatTaskId);
        this.ping(options);
      }

      if (options.onMessage) {
        options.onMessage(event);
      }
    };

    ws.onclose = (event): void => {
      console.log('Connection closed.', event.code);
      // 关闭心跳机制
      clearTimeout(ws.pingTaskId);

      if (options.onClose) {
        options.onClose(event);
      }
    };

    ws.onerror = (event): void => {
      console.log('Connection error');

      if (options.onError) {
        options.onError(event);
      }
    };
    return ws;
  }

  /**
   * 关闭webSocket连接
   */
  public disConnect(key: Key): void {
    if (!this.webSocketMap.has(key)) {
      return;
    }
    const ws = this.webSocketMap.get(key)!;
    if (ws.heartbeatTaskId) {
      clearTimeout(ws.heartbeatTaskId);
    }
    ws.close();
    this.webSocketMap.delete(key);
  }

  /**
   * 获取用于某个业务的websocket连接
   * @param businessScope 业务范围
   * @returns
   */
  public getInstance(key: Key): OsWebsocket | undefined {
    return this.webSocketMap.get(key);
  }

  /**
   * 心跳机制核心, 定时ping服务端
   * @param options
   * @returns
   */
  private ping(options: ConnectOptions): NodeJS.Timeout {
    const ws = this.getInstance(options.key)!;
    return setTimeout(() => {
      ws.send('0x9');
      ws.heartbeatTaskId = setTimeout(() => {
        this.disConnect(options.key);
        this.connect(options);
      }, ws.waitPongTimeOut!);
    }, ws.pingTimeInterval);
  }

  /**
    如果连接超时，尝试重新连接
   * @param options
   * @returns
   */
  private handleConnectTimeOut(options: ConnectOptions, ws: OsWebsocket): NodeJS.Timeout {
    return setTimeout(() => {
      ws.close();
      this.connect(options);
    }, ws.openTimeOut);
  }
}

export const webSocket = new MyWebSocket();
