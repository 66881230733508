import { ApiResponse } from '@/api/axios';
import { SortOrder } from 'element-ui/types/table';

export type Query<T> = {
  [P in keyof T]?: T[P];
};
export interface Paging {
  currentPage: number;
  showCount: number;
}
export interface SortOptions<T> {
  prop: keyof T;
  order: SortOrder;
}
export abstract class BaseService<T> {
  public abstract getById(id: number): Promise<Partial<T>>;
  public abstract getList(
    query?: Partial<T> & { [propName: string]: any },
    paging?: Paging
  ): Promise<Array<T> | ApiResponse<Array<Partial<T>>>>;
  public abstract post(resource: T): Promise<void>;
  public abstract put(resource: T): Promise<void>;
  public abstract delete(id: number, ...items: any[]): Promise<void>;
}
