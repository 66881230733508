/**
 * 生产订单类型
 */
export enum ProductionOrderTypeEnum {
  /**
   * 线上订单
   */
  online = 1,
  /**
   * 线下订单
   */
  offline = 2
}
