import { ApiError } from '@/api/axios';
import i18n from '@/lang';
import { ErrorLevelEnum } from '@/resource/enum';
import { BatchOperationError } from '@/resource/model/common';

const prefixName = 'requestError';
const constantError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  // 公共异常
  20113: { level: ErrorLevelEnum.error, i18nKey: 'tokenVerifyError' },
  20111: { level: ErrorLevelEnum.warning, i18nKey: 'tokenExpired' },
  10003: { level: ErrorLevelEnum.error, i18nKey: 'requireParameter' },
  10001: { level: ErrorLevelEnum.error, i18nKey: 'invalidParameter' },
  10009: { level: ErrorLevelEnum.error, i18nKey: 'importFailed' },
  10011: { level: ErrorLevelEnum.error, i18nKey: 'nameRepeat' },
  10012: { level: ErrorLevelEnum.error, i18nKey: 'fileNoData' },
  10013: { level: ErrorLevelEnum.error, i18nKey: 'downloadFailed' },
  30005: { level: ErrorLevelEnum.error, i18nKey: 'downloadFailed' },
  10005: { level: ErrorLevelEnum.error, i18nKey: 'fileReadFailed' },
  '-4': { level: ErrorLevelEnum.error, i18nKey: 'timeout' },
  20910: { level: ErrorLevelEnum.warning, i18nKey: 'enterpriseInfoNot' },
  // 登录模块
  20102: { level: ErrorLevelEnum.error, i18nKey: 'nameOrPassError' },
  20101: { level: ErrorLevelEnum.warning, i18nKey: 'userNotLogin' },
  20103: { level: ErrorLevelEnum.error, i18nKey: 'forbiddenAcccount' },
  20104: { level: ErrorLevelEnum.error, i18nKey: 'forbiddenCompany' },
  20106: { level: ErrorLevelEnum.error, i18nKey: 'noPermission' },
  20201: { level: ErrorLevelEnum.error, i18nKey: 'nameNotExist' },

  // 找回密码
  20117: { level: ErrorLevelEnum.error, i18nKey: 'sendVerifyCodeError' },
  20118: { level: ErrorLevelEnum.error, i18nKey: 'emailFormatError' },

  // 该邮箱未与任何账户建立联系
  20120: { level: ErrorLevelEnum.error, i18nKey: 'emailNorrelated' },
  20121: { level: ErrorLevelEnum.error, i18nKey: 'companyNotExist' },
  20122: { level: ErrorLevelEnum.error, i18nKey: 'resetPasswordError' },
  20123: { level: ErrorLevelEnum.error, i18nKey: 'passwordFormatError' },

  // 密码解密失败
  20125: { level: ErrorLevelEnum.error, i18nKey: 'passwordDecryptError' },

  // 导入
  20207: { level: ErrorLevelEnum.error, i18nKey: 'importFailed' },

  // 资源模块
  20401: { level: ErrorLevelEnum.error, i18nKey: 'rsourceNameRepeat' },
  20406: { level: ErrorLevelEnum.error, i18nKey: 'resourceParentNull' },
  20404: { level: ErrorLevelEnum.error, i18nKey: 'hasChildResource' },
  20405: { level: ErrorLevelEnum.error, i18nKey: 'deleteResourceError' },

  // 部门管理
  20603: { level: ErrorLevelEnum.error, i18nKey: 'hasPosition' },
  20601: { level: ErrorLevelEnum.error, i18nKey: 'departmentNameRepeat' },
  20602: { level: ErrorLevelEnum.error, i18nKey: 'parentDepartmentNoExit' },
  20604: { level: ErrorLevelEnum.error, i18nKey: 'hasChildDepartment' },

  // 角色管理
  22801: { level: ErrorLevelEnum.error, i18nKey: 'roleNameRepeat' },
  21802: { level: ErrorLevelEnum.warning, i18nKey: 'roleIsUsing' },

  // 岗位管理
  20503: { level: ErrorLevelEnum.warning, i18nKey: 'positionIsUsing' },
  20502: { level: ErrorLevelEnum.error, i18nKey: 'positionNameRepeat' },

  // 人员管理
  20209: { level: ErrorLevelEnum.error, i18nKey: 'userNameRepeat' },
  20210: { level: ErrorLevelEnum.error, i18nKey: 'emailRepeat' },
  20211: { level: ErrorLevelEnum.error, i18nKey: 'mobileRepeat' },

  // 修改密码
  20206: { level: ErrorLevelEnum.error, i18nKey: 'passwordError' },

  // 供应商产品
  22301: { level: ErrorLevelEnum.error, i18nKey: 'printingMaterialCanNotUse' },
  22302: { level: ErrorLevelEnum.error, i18nKey: 'printingCraftCanNotUse' },
  22303: { level: ErrorLevelEnum.error, i18nKey: 'backendMaterialCanNotUse' },
  22304: { level: ErrorLevelEnum.error, i18nKey: 'backendCraftCanNotUse' },

  // 发货通知单
  22908: { level: ErrorLevelEnum.error, i18nKey: 'unDelivery' },
  22917: { level: ErrorLevelEnum.error, i18nKey: 'hasLogisticOrderCanNotCancel' },
  22918: { level: ErrorLevelEnum.error, i18nKey: 'notCancelFinish' },

  // 物流单
  23101: { level: ErrorLevelEnum.error, i18nKey: 'receivingStatusUnMatched' },
  23102: { level: ErrorLevelEnum.error, i18nKey: 'orderNotReadyForDelivery' },
  23108: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderSender' },
  23109: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderSenderTel' },
  23115: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderNo' },
  23116: { level: ErrorLevelEnum.error, i18nKey: 'logisticsOrderExpressName' },
  23127: { level: ErrorLevelEnum.error, i18nKey: 'orderNotclose' },

  // 单据管理
  22805: { level: ErrorLevelEnum.error, i18nKey: 'orderItemTakeError' },
  22806: { level: ErrorLevelEnum.error, i18nKey: 'orderItemFinishError' },
  22810: { level: ErrorLevelEnum.error, i18nKey: 'canNotReturnNotOpenProcess' },
  22811: { level: ErrorLevelEnum.error, i18nKey: 'prepressUnfinished' },
  22814: { level: ErrorLevelEnum.error, i18nKey: 'loyoutNotFound' },
  22817: { level: ErrorLevelEnum.error, i18nKey: 'platformgPrepressNotOpen' },
  22818: { level: ErrorLevelEnum.error, i18nKey: 'orderItemExistChangeOrder' },

  // 生产订单
  22701: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderExist' },
  22702: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderNotExist' },
  22704: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotNew' },
  22705: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitConfirm' },
  22706: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitAccept' },
  22707: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotWaitSchedule' },
  22708: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderStatusNotInProgress' },
  22709: { level: ErrorLevelEnum.warning, i18nKey: 'productCountOver' },
  22710: { level: ErrorLevelEnum.warning, i18nKey: 'productionOrderDetailNotExist' },

  // 生产订单变更单
  23001: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogCanNotPush' },
  23002: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogCanNotConfirm' },
  23003: { level: ErrorLevelEnum.warning, i18nKey: 'existChangeLog' },
  23004: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogNotExist' },
  23005: { level: ErrorLevelEnum.warning, i18nKey: 'changeProductNotExist' },
  23006: { level: ErrorLevelEnum.warning, i18nKey: 'supplierProductNotExist' },
  23007: { level: ErrorLevelEnum.warning, i18nKey: 'canNotChangeComplatedOrder' },
  23008: { level: ErrorLevelEnum.warning, i18nKey: 'changeLogNoDetail' },
  23010: { level: ErrorLevelEnum.warning, i18nKey: 'sentDownItem' },
  22802: { level: ErrorLevelEnum.warning, i18nKey: 'billInProduction' },
  // 反馈建议
  24601: { level: ErrorLevelEnum.error, i18nKey: 'feedbackCloseSubmit' },
  24602: { level: ErrorLevelEnum.error, i18nKey: 'feedbackTimeout' },
  24603: { level: ErrorLevelEnum.error, i18nKey: 'feedbackSubmitExceed' }
};

/**
 * 获取api响应结果中的异常信息
 * @param code 异常码
 * @returns 经由国际化处理后的异常信息
 */
export const getErrorObj = (code: number): ApiError => {
  const key = `${prefixName}.${constantError[code]?.i18nKey || 'unknownError'}`;
  return {
    code: code,
    level: constantError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

const httpPrefixName = 'httpRequestError';
const constantHttpError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  400: { level: ErrorLevelEnum.error, i18nKey: 'requestError' },
  401: { level: ErrorLevelEnum.error, i18nKey: 'unAuthorized' },
  403: { level: ErrorLevelEnum.error, i18nKey: 'serverDeniedAccess' },
  404: { level: ErrorLevelEnum.error, i18nKey: 'resourceNotFound' },
  405: { level: ErrorLevelEnum.error, i18nKey: 'methodDisabled' },
  408: { level: ErrorLevelEnum.error, i18nKey: 'timeout' },
  500: { level: ErrorLevelEnum.error, i18nKey: 'serverError' },
  501: { level: ErrorLevelEnum.error, i18nKey: 'serverNotSupport' },
  502: { level: ErrorLevelEnum.error, i18nKey: 'networkError' },
  503: { level: ErrorLevelEnum.error, i18nKey: 'serveDied' },
  504: { level: ErrorLevelEnum.error, i18nKey: 'neworkTimeout' },
  505: { level: ErrorLevelEnum.error, i18nKey: 'httpVersionNotSupport' },
  5001: { level: ErrorLevelEnum.info, i18nKey: 'httpCanceled' },
  5002: { level: ErrorLevelEnum.warning, i18nKey: 'oftenRequest' }
};

/**
 * 根据http响应异常状态码，返回异常信息
 * @param code 异常状态码
 * @returns 经由国际化处理后的异常信息
 */
export const getHttpErrorObj = (code?: number): ApiError => {
  if (!code) {
    return {
      level: ErrorLevelEnum.error,
      message: i18n.t(`${httpPrefixName}.otherError`) as string
    };
  }
  const key = `${httpPrefixName}.${constantHttpError[code]?.i18nKey || 'otherError'}`;
  return {
    code: code,
    level: constantHttpError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

export const getBatchOperationErrorObjs = (errors: Array<BatchOperationError>): Array<ApiError> => {
  if (!errors || errors.length === 0) {
    return [
      {
        level: ErrorLevelEnum.error,
        message: i18n.t(`${httpPrefixName}.otherError`) as string
      }
    ];
  }
  return errors.map(x => {
    const obj = getErrorObj(x.code);
    obj.message = `${x.key} : ${obj.message}`;
    return obj;
  });
};
