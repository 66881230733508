import { Paging } from '@/api/base';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'os-pagination'
})
export default class OsPagination extends Vue {
  @Prop({ type: Object, required: true }) public paging!: Paging;

  @Prop({ type: Number, required: true }) public total!: number;

  @Prop({
    type: Array,
    default: () => {
      return [50, 100, 200];
    },
    required: false
  })
  public pageSizeOption!: Array<number>;

  public created(): void {
    if (this.pageSizeOption[0] !== this.paging.showCount) {
      this.pageSizeOption.unshift(this.paging.showCount);
    }
  }

  public handleSizeChange(): void {
    this.$emit('pagination');
  }

  public handleCurrentChange(): void {
    this.$emit('pagination');
  }
}
