/**
 * 生产订单详情变更类型
 */
export enum ChangeTypeEnum {
    /**
     * 停止生产
     */
    stopProduction = 1,
    /**
     * 增加产品
     */
    addProduct
}