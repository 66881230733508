import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OsTableColumn } from '../os-table/os-table';
import Sortable from 'sortablejs';

@Component({
  i18n: {
    messages: {
      zh: {
        operation: {
          checkAll: '全选',
          reset: '重置'
        }
      },
      en: {
        operation: {
          checkAll: 'Check all',
          reset: 'Reset'
        }
      }
    }
  }
})
export default class OsTableCustom extends Vue {
  /**
   * 用于自定义列的原始数据
   */
  @Prop({
    required: true,
    type: Array
  })
  public columns!: Array<OsTableColumn<any>>;

  /**
   * 禁止自定义的列，支持传入prop数组
   */
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return ['id'];
    }
  })
  public disableCustomColumns!: Array<string>;

  public columnsOption: Array<OsTableColumn<any>> = [];

  /**
   * 全选列
   */
  private checkAll = false;

  /**
   * 控制全选checkbox是否全局选中的样式
   */
  private isIndeterminate = true;

  /**
   * 选中的列（prop）
   */
  private checkedColumns: Array<string> = [];

  /**
   * 重置列配置到初始状态
   */
  public resetColumns(): void {
    this.$emit('reset');
  }

  /**
   * popover打开时执行的钩子
   */
  private onShow(): void {
    this.columnDrag();
  }

  private confirmColumnSetting(): void {
    const columns = this.columns.map(x => {
      x.hide = !this.checkedColumns.includes(x.prop);
      return x;
    });
    this.$emit('change', columns);
  }

  /**
   * 该列是否允许自定义
   * @param prop 列标识
   * @returns
   */
  private allowCustom(prop: string): boolean {
    return !this.disableCustomColumns.includes(prop);
  }

  /**
   * 列拖拽
   */
  private columnDrag(): void {
    const columnsDoms = [...document.querySelectorAll('.el-checkbox-group')!] as Array<HTMLElement>;
    for (const columnsDom of columnsDoms) {
      Sortable.create(columnsDom, {
        animation: 180,
        delay: 0,
        onEnd: event => {
          const dropColumn = this.columns[event.oldIndex!];
          this.columns.splice(event.oldIndex!, 1);
          this.columns.splice(event.newIndex!, 0, dropColumn);
          this.confirmColumnSetting();
        }
      });
    }
  }

  /**
   * 处理全选checkbox的变化
   * @param value 是否选中
   */
  private handleCheckAllChange(isChecked: boolean): void {
    this.checkedColumns = isChecked ? this.columns.map(x => x.prop) : [];
    this.isIndeterminate = false;
    this.confirmColumnSetting();
  }

  /**
   * 处理列选中或取消的变化
   * @param checkedValues 当前选中的数据
   */
  private handleCheckedColumnChange(checkedValues: Array<string>): void {
    const checkedCount = checkedValues.length;
    this.checkAll = checkedCount === this.columns.length;
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.columns.length;
    this.confirmColumnSetting();
  }
  /**
   * 初始化选中列
   */
  @Watch('columns', { immediate: true })
  private initCheckedColumn(): void {
    const displayColumn = this.columns.filter(x => !x.hide);
    this.checkedColumns = displayColumn.map(x => x.prop);
    this.isIndeterminate = this.checkedColumns.length < displayColumn.length;
    this.checkAll = this.checkedColumns.length === displayColumn.length;
  }
}
