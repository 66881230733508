export const enum DeviceStatusEnum {
    /**
     * 启用
     */
    enable = 1,
    /**
     * 禁用
     */
    disable = 2,
    /**
     * 维修中
     */
    repair = 3
}