import { queryPlanService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { QueryPlanResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class EditQueryPlan extends mixins(DialogMixin) {
  @Prop({ required: true, type: Number, default: 0 }) public id!: number;
  @Prop({ required: false, type: Number, default: 0 }) public code!: number;
  @Prop({ required: true, type: String, default: '' }) public json!: string;
  @Prop({ required: true, type: String, default: '' }) public queryPlanName!: string;
  public queryPlanFrom: QueryPlanResource = {
    id: 0,
    code: this.code,
    name: '',
    json: this.json
  };

  public resourceFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('queryPlan.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
      // {
      //   validator: (rule: any, value: string, callback: Function): void => {
      //     queryPlanService
      //       .checkProjectName(value, this.pageCode!, this.queryPlanFrom?.id)
      //       .then((isRepeat: boolean) => {
      //         if (isRepeat) {
      //           callback(translation('requestError.nameRepeat'));
      //           return;
      //         }
      //         callback();
      //       })
      //       .catch(error => {
      //         callback(error);
      //       });
      //   },
      //   trigger: 'blur'
      // }
    ],
    cycle: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('project.selectCycle')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    currencyType: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('project.selectSettleCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.id === 0) {
      this.title = 'queryPlan.newTitle';
      this.operationType = 'add';
      this.queryPlanFrom.name = '';
      return;
    }
    this.title = 'queryPlan.editTitle';
    this.operationType = 'edit';
    this.queryPlanFrom.name = this.queryPlanName;
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.queryPlanFrom as Form).resetFields();
  }

  public async onSubmit(): Promise<void> {
    try {
      this.queryPlanFrom.id = this.id;
      this.queryPlanFrom.json = this.json;
      this.queryPlanFrom.code = this.code;
      this.setLoading(true);
      await (this.$refs.queryPlanFrom as Form).validate();

      const queryPlan = ({ ...this.queryPlanFrom } as any) as QueryPlanResource;
      if (this.operationType === 'add') {
        await queryPlanService.post(queryPlan);
        Message.success(translation('operationRes.addSuccess'));
        this.$emit('save-success');
        this.closeDialog();
        return;
      }
      await queryPlanService.put(queryPlan);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('save-success', queryPlan);
      this.closeDialog();
    } catch (error) {
      if (error && error !== 'cancel') {
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }
}
