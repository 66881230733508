/**
 * 生产订单状态
 */
 export enum ProductionOrderStatusEnum {
    /**
     * 待接单
     */
    waitingAccept = 2,
    /**
     * 待排产
     */
    waitingPlan = 3,
    /**
     * 生产中
     */
    inProgress = 4,
    /**
     * 待确认
     */
    waitingConfirm = 5,
    /**
     * 已完成
     */
    completed = 6
  }
  