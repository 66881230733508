import { DutyAgencyTypeEnum, ResourceUrlEnum } from '@/resource/enum';
import { ConsumeItemList, ConsumeItemListQuery, ProductBrokenResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class ConsumeService {
  public getById(id: number): Promise<ProductBrokenResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductBrokenResource>(ResourceUrlEnum.consume, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: ProductBrokenResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<ProductBrokenResource>(ResourceUrlEnum.consume, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<ConsumeItemListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ConsumeItemList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ConsumeItemList>>(`${ResourceUrlEnum.consume}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出耗损数据
   * @param query
   * @returns
   */
  public export(query?: Partial<ConsumeItemListQuery>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.consume}/exportData`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 单据耗损
   * @param resource
   * @returns
   */
  public post(resource: ProductBrokenResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`production/orderItem/reportAttrition`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.consume, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取责任机构类型
   * @returns
   */
  public getDutyAgencyTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in DutyAgencyTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `dutyAgencyType.${key}`,
          value: DutyAgencyTypeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取客户名称
   * @returns
   */
  public getcustomerNameList(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<any>>(`${ResourceUrlEnum.consume}/getCustomer`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查看项次的耗损记录
   * @param orderItemId 项次id
   * @param paging
   * @returns
   */
  public getConsumeRecord(
    orderItemId: number,
    paging?: Paging
  ): Promise<ApiResponse<Array<ConsumeItemList>>> {
    const cloneQuery = { orderItemId };

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ConsumeItemList>>(
          `${ResourceUrlEnum.productionOrderProduct}/attritionListPage`,
          cloneQuery
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const consumeService = new ConsumeService();
export default consumeService;
