import { PermissionModule } from '@/store/modules/permission';
import { compile } from 'path-to-regexp';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RedirectOption, Route, RouteConfig } from 'vue-router';

interface RouteLine {
  title: string;
  redirect?: RedirectOption;
  path: string;
  name: string;
}
@Component({
  name: 'breadcrumb'
})
export default class Breadcrumb extends Vue {
  private breadcrumbs: Array<RouteLine> = [];

  private dashBoardName = 'Dashboard';
  public created(): void {
    this.getBreadcrumb();
  }

  @Watch('$route')
  private onRouteChange(route: Route): void {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb();
  }

  private getBreadcrumb(): void {
    this.breadcrumbs = [];
    // 先找出当前激活路由的所有父级路由
    if (this.$route.meta?.parentId) {
      this.getParentRouteLine(PermissionModule.routes, this.$route.meta.parentId);
    }
    // 然后把当前路由push到面包屑数组尾部
    this.breadcrumbs.push({
      title: this.$route.meta?.title || 'no-name',
      name: this.$route.name!,
      path: this.$route.path
    });
    // 如果头部不是dashboard，则把dashboard加入到面包屑数组头部
    if (!this.isDashboard(this.breadcrumbs[0].name)) {
      this.breadcrumbs.unshift({ path: '/dashboard', title: 'route.dashboard', name: this.dashBoardName });
    }
  }

  private isDashboard(name: string): boolean {
    return name === this.dashBoardName;
  }

  private pathCompile(path: string): string {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const { params } = this.$route;
    const toPath = compile(path);
    return toPath(params);
  }

  private handleLink(item: RouteConfig): void {
    const { redirect, path } = item;
    if (redirect) {
      this.$router.push(redirect as any);
      return;
    }
    this.$router.push(this.pathCompile(path));
  }

  private getParentRouteLine(routes: Array<RouteConfig>, parentId: number): void {
    for (const route of routes) {
      if (route.meta?.id === parentId) {
        this.breadcrumbs.unshift({
          redirect: route.redirect,
          title: route.meta?.title || 'no-name',
          path: route.path,
          name: route.name!
        });
        // 如果父级路由还存在上级，则继续查询父级路由
        if (route.meta?.parentId) {
          this.getParentRouteLine(routes, route.meta.parentId);
        }
      } else if (route.children && route.children.length > 0) {
        this.getParentRouteLine(route.children, parentId);
      }
    }
  }
}
