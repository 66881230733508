/**
 * 消息状态
 */
export enum MessageStatusEnum {
  /**
   * 未读
   */
  unRead = 1,
  /**
   * 已读
   */
  readed,
  /**
   * 已删除
   */
  deleted
}

/**
 * 消息通知类型
 */
export enum MessageTypeEnum {
  notice = 1,
  todo = 2
}
