import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import Ecb from 'crypto-js/mode-ecb';
import Pkcs7 from 'crypto-js/pad-pkcs7';

/**
 * 加密
 * @param word 要加密的内容
 * @param secretKey 秘钥
 * @returns
 */
export function aesEncrypt(word: string, secretKey: string): string {
  const key = Utf8.parse(secretKey);
  const srcs = Utf8.parse(word);
  const encrypted = AES.encrypt(srcs, key, { mode: Ecb, padding: Pkcs7 });
  return encrypted.toString();
}
