import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DeviceType, AppModule } from '@/store/modules/app';
import { AppMain, NavBar, SideBar, TagsView } from './components';
import ResizeMixin from './mixin/resize';
import MiniLogin from '@/views/login/mini-login/mini-login.vue';
@Component({
  name: 'layout',
  components: {
    AppMain,
    NavBar,
    SideBar,
    TagsView,
    MiniLogin
  }
})
export default class Layout extends mixins(ResizeMixin) {
  public handleClickOutside(): void {
    AppModule.CloseSideBar(false);
  }
  public get classObj(): {
    hideSidebar: boolean;
    openSidebar: boolean;
    withoutAnimation: boolean;
    mobile: boolean;
  } {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    };
  }
}
