import { Component, Vue } from 'vue-property-decorator';

const tagSpacing = 4;

@Component({
  name: 'ScrollPane'
})
export default class ScrollPane extends Vue {
  public get scrollWrapper(): HTMLElement {
    return (this.$refs.scrollContainer as Vue).$refs.wrap as HTMLElement;
  }

  public mounted(): void {
    this.scrollWrapper.addEventListener('scroll', this.emitScroll, true);
  }

  public beforeDestroy(): void {
    this.scrollWrapper.removeEventListener('scroll', this.emitScroll);
  }

  public moveToTarget(currentTag: HTMLElement): void {
    const container = (this.$refs.scrollContainer as Vue).$el as HTMLElement;
    const containerWidth = container.offsetWidth;
    const scrollWrapper = this.scrollWrapper;
    const tagList = this.$parent.$refs.tag as any[];

    let firstTag = null;
    let lastTag = null;

    // 找到第一个标签和最后一个标签
    if (tagList.length > 0) {
      firstTag = tagList[0];
      lastTag = tagList[tagList.length - 1];
    }

    if (firstTag === currentTag) {
      scrollWrapper.scrollLeft = 0;
    } else if (lastTag === currentTag) {
      scrollWrapper.scrollLeft = scrollWrapper.scrollWidth - containerWidth;
    } else {
      // 找到 preTag 和 nextTag
      const currentIndex = tagList.findIndex(item => item === currentTag);
      const prevTag = tagList[currentIndex - 1];
      const nextTag = tagList[currentIndex + 1];
      // 标签的 offsetLeft 在 nextTag 之后
      const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagSpacing;
      // 标签在 prevTag 之前的 offsetLeft
      const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagSpacing;

      if (afterNextTagOffsetLeft > scrollWrapper.scrollLeft + containerWidth) {
        scrollWrapper.scrollLeft = afterNextTagOffsetLeft - containerWidth;
      } else if (beforePrevTagOffsetLeft < scrollWrapper.scrollLeft) {
        scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
      }
    }
  }

  private handleScroll(e: WheelEvent): void {
    const eventDelta = (e as any).wheelDelta || -e.deltaY * 40;
    const scrollWrapper = this.scrollWrapper;
    scrollWrapper.scrollLeft = scrollWrapper.scrollLeft + eventDelta / 4;
  }

  private emitScroll(): void {
    this.$emit('scroll');
  }
}
