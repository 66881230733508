/**
 * 公司类型
 */
export enum CustomerScopeEnum {
  /**
   * 客户
   */
  client = 1,
  /**
   * 供应商
   */
  supplier = 2
}
